.ProfileAddImage {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 130px;
}

.ProfileAddImageInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding-top: 30px;
  padding-bottom: 0px;
  border-radius: 16px;
  min-height: 50vh;
  margin-bottom: 10vh;
}

.ProfileAddImageText {
  width: 80%;
  font-weight: 500;
  font-size: 15px;
}

.ProfileAddImagePreview {
  width: 80%;
  max-width: 300px;
  border-radius: 50%;
  aspect-ratio: 1;
}

.ProfileAddImagePreviewButtons {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 60%;
  margin-top: 30px;
}

.ProfileAddImageErrorText {
  width: 60%;
  font-weight: 400;
  font-size: 15px;
  min-height: 30px;
}

.ProfileAchivements {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0;
  margin-top: 3px;
  max-width: 800px;
}

.ProfileAchivementsLoading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.ProfileAchivementsInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ProfileAchivementsSlider {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ProfileAchivementsInnerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ProfileAchivementsTitle {
  font-weight: 500;
  font-size: 15px;
  margin: 0px;
  width: 90%;
  max-width: 800px;
  text-align: left;
}

.ProfileAchivementItem {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  z-index: 100;
}

.ProfileAchivementItem span {
  font-size: 30px;
  margin-bottom: 0px;
  width: 100%;
}
.ProfileAchivementItemText {
  font-weight: 700;
  font-size: 9px;
  margin: 0px;
  margin-top: 15px;
  width: 90%;
  text-align: center;
}

.ProfileReviewsOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 800px;
  margin: 0;
}
.ProfileReviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  margin-top: 10px;
}

.ProfileReviewsTitle {
  font-weight: 500;
  font-size: 15px;
  margin: 0px;
  width: 100%;
  text-align: left;
}

.ProfileReviewsItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ProfileReviewsItemTitle {
  font-weight: 400;
  font-size: 13px;
  margin: 0px;
  width: 100%;
  text-align: left;
}

.ProfileReviewsItemSub {
  font-weight: 500;
  font-size: 12px;
  margin: 0px;
  margin-top: 2px;
  margin-bottom: 0px;
  width: 100%;
  text-align: left;
}

.ProfileReviewsItemTextTitle {
  font-weight: 600;
  font-size: 12px;
  margin: 0px;
  margin-top: 4px;
  margin-bottom: 0px;
  width: 100%;
  text-align: left;
}

.ProfileReviewsItemText {
  font-weight: 400;
  font-size: 12px;
  margin: 0px;
  margin-top: 4px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}

.ProfileRatingItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  margin-top: 4px;
  margin-bottom: 3px;
  margin-left: -2px;
}

.ProfileReviewsItemDate {
  font-weight: 400;
  font-size: 11px;
  margin: 0px;
  flex: 1;
  font-style: italic;
  text-align: left;
  margin-left: 10px;
}

.ProfileRatingItemBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 5px;
}

.ProfileRatingItemBottomText {
  font-weight: 500;
  font-size: 9px;
  margin: 0px;
  text-align: left;
  margin-left: 2px;
}

.PfpCreator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 0;
  max-width: 800px;
  border-radius: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.PfpCreatorImgOuter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.PfpCreatorImg {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
}

.PfpCreatorButtons {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

@media (min-width: 350px) {
  .ProfileAvatar {
    width: 20%;
  }

  .ProfileAvatarText {
    font-size: 10px;
  }
  .ProfileAchivementsTitle {
    font-size: 16px;
  }
  .ProfileReviewsTitle {
    font-size: 16px;
  }
  .ProfileRatingItemBottomText {
    font-size: 11px;
  }

  .ProfileAchivementItemText {
    font-size: 10px;
  }

        .ProfileAchivementItem span {
          font-size: 35px;
        }
}

@media (min-width: 400px) {

  .ProfileAchivementItem {
    width: 14%;
  }

    .ProfileAchivementItem span {
      font-size: 40px;
    }
}

@media (min-width: 500px) {
  .PfpCreator {
    padding-top: 20px;
  }
  .PfpCreatorImgOuter {
    padding-bottom: 20px;
  }

  .PfpCreatorImg {
    width: calc(100% - 40px);
    border-radius: 8px 8px 8px 8px;
  }

  .ProfileAchivementItemText {
    font-weight: 700;
    font-size: 10px;
  }

        .ProfileAchivementItem span {
          font-size: 45px;
        }
}

@media (min-width: 550px) {
  .ProfileAvatar {
    width: 18%;
  }

  .ProfileAvatarText {
    font-size: 12px;
  }
  .ProfileAchivementsTitle {
    font-size: 17px;
  }
  .ProfileReviewsTitle {
    font-size: 17px;
  }
  .ProfileRatingItemBottomText {
    font-size: 12px;
  }
}

@media (min-width: 600px) {
  .PfpCreator {
    padding-top: 30px;
  }

  .PfpCreatorImgOuter {
    padding-bottom: 30px;
  }

  .PfpCreatorImg {
    width: calc(80% - 60px);
  }
}

@media (min-width: 700px) {
  .ProfileAvatarText {
    font-size: 13px;
  }
  .ProfileAchivementsTitle {
    font-size: 19px;
  }

  .ProfileReviewsTitle {
    font-size: 19px;
  }
  .ProfileRatingItemBottomText {
    font-size: 13px;
  }
  .PfpCreator {
    padding-top: 40px;
  }

  .PfpCreatorImgOuter {
    padding-bottom: 40px;
  }

  .PfpCreatorImg {
    width: calc(70% - 60px);
  }
  .ProfileAchivementItem {
    width: 10%;
  }
  .ProfileAchivementItem span {
    font-size: 40px;
  }

  .ProfileAchivementItemText {
    font-weight: 700;
    font-size: 11px;
  }
}

@media (min-width: 800px) {
  .PfpCreatorImg {
    width: 50%;
  }
}

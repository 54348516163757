.TokensList {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: -10px;
  padding-bottom: 120px;
  font-family: "Poppins", sans-serif;
}

.TokensListRowButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1px;
  margin-bottom: 1px;
}

.TokensListInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  max-width: 800px;
  margin-top: 5px;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.TokensListItemCollapsed {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% - 2px);
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.5s ease-in-out;
}

.TokensListItemCollapsedItem {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  transition: all 0.5s ease-in-out;
  margin-bottom: 10px;
  margin-top: 10px;
}

.TokensListItemCollapsedItemInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33.333% - 20px);
  margin: 10px;
}

.TokensListItemCollapsedItemTile {
  font-size: 8px;
  font-weight: 700;
  margin: 0px;
  margin-bottom: 2px;
  text-align: center;
}

.TokensListItemCollapsedItemSub {
  font-size: 10px;
  font-weight: 500;
  margin: 0px;
  text-align: center;
}

.TokensListItemCollapsedChartButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.TokensListItemCollapsedButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 350px) {
  .TokensListItemCollapsedItemTile {
    font-size: 10px;
  }

  .TokensListItemCollapsedItemSub {
    font-size: 12px;
  }
}

@media (min-width: 500px) {
  .TokensListItemCollapsedItemTile {
    font-size: 9px;
  }

  .TokensListItemCollapsedItemSub {
    font-size: 11px;
  }
  .TokensListItemCollapsedItemInner {
    width: calc(20% - 20px);
  }
}

@media (min-width: 600px) {
  .TokensListItemCollapsedItem {
    width: 46%;
  }
  .TokensListItemCollapsedButtons {
      flex-direction: column;
      width: 6%;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 1%;
  }
  .TokensListItemCollapsedItemInner {
      width: calc(33.333% - 20px);
  }
}

@media (min-width: 800px) {

    .TokensListItemCollapsedItemInner {
        width: calc(25% - 20px);
    }
}
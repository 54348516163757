.ListingsItem {
  width: calc(100% - 24px);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 15px;
  padding: 12px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.ListingItemAvatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
}

.ListingItemDivider {
  flex: 1;
}

.ListingsItemInner {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0px;
  text-decoration: none;
  margin-left: 10px;
  overflow: hidden;
}

.ListingsItemInnerHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0;
  margin-top: -1px;
  overflow: hidden;
  max-width: 95%;
}

.ListingsItemTitle {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  margin: 0;
}

.ListingsItemTitleSub {
  font-size: 80%;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  margin: 0;
  margin-left: 4px;
  margin-bottom: 1px;
}

.ListingsItemDesc {
  font-size: 9px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  margin: 0;
  width: 95%;
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.ListingsItemButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 0px;
  padding: 0px;
}

.ListingsItemButtonsLink {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0px;
  padding: 0px;
}

.ProjectListItemSmall {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0px 15px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease;
  overflow: hidden;
}

.ProjectListItemMedium {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0px 15px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease;
  overflow: hidden;
}

.ProjectListItemLarge {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 0px 15px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease;
  overflow: hidden;
}

.ProjectListItemHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  overflow: hidden;
}

.ProjectListItemHeaderClickable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 10px 0px;
  overflow: hidden;
  flex: 1;
  max-width: 90%;
  cursor: pointer;
}

.ProjectListItemAvatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 20px;
  min-height: 20px;
}

.ProjectListItemAvatarSmall {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 14px;
  min-height: 14px;
}

.ProjectListItemAvatarMedium {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 32px;
  min-height: 32px;
}

.ProjectListItemAvatarLarge {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
  min-height: 40px;
}

.ProjectListItemHeaderTextOuterDivMedium {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  padding: 0;
  flex: 1;
  overflow: hidden;
}
.ProjectListItemHeaderTextOuterDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  margin-left: 8px;
  padding: 0;
  width: calc(100% - 8px);
  overflow: hidden;
}
.ProjectListItemHeaderTitleOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  overflow: visible;
  flex: 1;
}

.ProjectListItemHeaderTitle {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  text-decoration: none;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
}

.ProjectListItemHeaderTitleLarge {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  text-decoration: none;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
}

.ProjectListItemHeaderCategory {
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
  text-decoration: none;
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ProjectListItemHeaderBadge {
  width: 12.5px;
  margin-left: 5px;
  margin-top: -1px;
  margin-bottom: -2px;
  overflow: visible;
}

.ProjectListItemHeaderBadgeRugged {
  width: 16px;
  margin-left: 5px;
  overflow: visible;
  margin-top: -2px;
  margin-bottom: -1px;
}

.ProjectListItemHeaderBadgeSmall {
  width: 11px;
  margin: 0px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: -2px;
}

.ProjectListItemHeaderBadgeLarge {
  width: 17px;
  margin-left: 5px;
  margin-top: -1px;
  margin-bottom: -2px;
  overflow: visible;
}

.ProjectListItemHeaderBadgeRuggedLarge {
  width: 20px;
  margin-left: 5px;
  overflow: visible;
  margin-top: -2px;
  margin-bottom: -1px;
}

.ProjectListItemHeaderIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.ProjectListItemHeaderIconsMedium {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}

.ProjectListItemHeaderRatingOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: calc(100% - 8px);
  margin-left: 7px;
}

.ProjectListItemInnerLarge {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  padding-bottom: 15px;
  cursor: pointer;
}

.ProjectListItemHeaderRatingOuterLarge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: calc(100%);
  margin-top: -5px;
}

.ProjectListItemInnerLargeText {
  font-size: 8px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  /* number of lines to show */
  line-clamp: 7;
  -webkit-box-orient: vertical;
  line-height: 1.25;
  width: 100%;
}

.UserListItemInnerLargeText {
  font-size: 8px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 18.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.25;
  width: 100%;
}

.ReviewListItemInnerLargeText {
  font-size: 9px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 15px;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.25;
  width: 100%;
}

.ProjectListItemFooterText {
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 0px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.UserListItemInnerLargeFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: -10px;
  padding: 0;
  width: 100%;
  overflow: hidden;
  margin-bottom: 15px;
}

.UserListItemFooterText {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  margin: 0;
}

.UserListItemFooterIcon {
  width: 23px;
  height: 23px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ReviewListItemRatingOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: calc(100%);
}

.ReviewListItemDialog {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  overflow: hidden;
}

.ReviewListItemDialogLargeText {
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 15px;
  line-height: 1.25;
  width: 100%;
}

.ProjectListItemFooterIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
}

.ProjectListItemFooterIconsInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-right: 20px;
}

.RatingsListItemFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  margin-bottom: 10px;
}

.RatingsListItemFooterText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: -5px;
}

.RatingsListItemFooterLinkText {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  text-decoration: none;
  margin: 0;
  white-space: nowrap;
}

.RatingsListItemFooterText svg {
  width: 13px;
  height: 13px;
}

.ReviewListItemDialogFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  margin-bottom: 5px;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  text-decoration: none;
  margin-top: 10px;
}

.ProjectListItemSmallPreview {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0px 15px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease;
  overflow: hidden;
  margin-left: 0px;
    margin-right: 0px;
}

.ProjectListItemMediumPreview {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0px 15px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease;
  overflow: hidden;
  margin-left: 0px;
    margin-right: 0px;
}

.ProjectListItemLargePreview {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  padding: 0px 15px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease;
  overflow: hidden;
margin-left: 0px;
  margin-right: 0px;
}

@media (min-width: 350px) {
  .ListingsItemTitle {
    font-size: 13px;
  }

  .ListingsItemDesc {
    font-size: 10px;
  }

  .UserListItemAvatar {
    width: 30px;
    height: 30px;
  }
  .ListingItemAvatar {
    width: 75px;
    height: 75px;
  }
}

@media (min-width: 550px) {
  .ListingsItemTitle {
    font-size: 14px;
  }

  .ListingsItemDesc {
    font-size: 11px;
  }

  .UserListItemAvatar {
    width: 34px;
    height: 34px;
  }
  .ListingItemAvatar {
    width: 85px;
    height: 85px;
  }
}

@media (min-width: 701px) {
  .ListingsItemTitle {
    font-size: 15px;
  }

  .ListingsItemDesc {
    font-size: 12px;
  }

  .UserListItemAvatar {
    width: 38px;
    height: 38px;
  }
  .ListingItemAvatar {
    width: 95px;
    height: 95px;
  }
}

@media (min-width: 400px) {
  .ProjectListItemAvatar {
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
  }
  .ProjectListItemAvatarSmall {
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
  }

  .ProjectListItemAvatarMedium {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  .ProjectListItemAvatarLarge {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }

  .ProjectListItemHeaderTitle {
    font-size: 15px;
    margin-left: 2px;
  }

  .ProjectListItemHeaderTitleLarge {
    font-size: 17px;
    margin-left: 4px;
  }

  .ProjectListItemHeaderCategory {
    font-size: 11px;
  }

  .ProjectListItemHeaderBadge {
    width: 15px;
    margin-left: 7px;
    margin-top: -1px;
    margin-bottom: -2px;
  }

  .ProjectListItemHeaderBadgeRugged {
    width: 20px;
    margin-left: 7px;
    margin-top: -2px;
    margin-bottom: -1px;
  }

  .ProjectListItemHeaderBadgeLarge {
    width: 20px;
    margin-left: 10px;
  }

  .ProjectListItemHeaderBadgeRuggedLarge {
    width: 24px;
    margin-left: 10px;
  }

  .ProjectListItemInnerLargeText {
    font-size: 10px;
    height: 88px;
  }

  .ProjectListItemFooterText {
    font-size: 12px;
  }
  .UserListItemInnerLargeText {
    font-size: 10px;
    height: 24px;
  }
  .ReviewListItemInnerLargeText {
    font-size: 11px;
    height: 56px;
  }
  .ReviewListItemDialogLargeText {
    font-size: 11px;
  }

  .RatingsListItemFooterLinkText {
    font-size: 14px;
  }

  .RatingsListItemFooterText svg {
    width: 15px;
    height: 15px;
  }

  .ReviewListItemDialogFooter {
    font-size: 13px;
  }
}

@media (min-width: 600px) {
  .ProjectListItemAvatar {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  .ProjectListItemAvatarMedium {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  }

  .ProjectListItemAvatarLarge {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  .ProjectListItemHeaderTitle {
    font-size: 12px;
    margin-left: 0px;
  }

  .ProjectListItemHeaderTitleLarge {
    font-size: 14px;
    margin-left: 2px;
  }

  .ProjectListItemHeaderCategory {
    font-size: 8px;
  }

  .ProjectListItemHeaderBadge {
    width: 12px;
    margin-left: 5px;
    margin-top: -1px;
    margin-bottom: -2px;
  }

  .ProjectListItemHeaderBadgeRugged {
    width: 16px;
    margin-left: 5px;
    margin-top: -2px;
    margin-bottom: -1px;
  }
  .ProjectListItemHeaderBadgeLarge {
    width: 17px;
    margin-left: 7px;
  }

  .ProjectListItemHeaderBadgeRuggedLarge {
    width: 20px;
    margin-left: 7px;
  }
  .ProjectListItemSmall {
    width: calc(50% - 40px);
  }

  .ProjectListItemMedium {
    width: calc(50% - 40px);
  }

  .ProjectListItemLarge {
    width: calc(50% - 40px);
  }

  .ProjectListItemSmallPreview {
    width: calc(50% - 50px);
    margin-left: 10px;
    margin-right: 10px;
  }

  .ProjectListItemMediumPreview {
    width: calc(50% - 50px);
    margin-left: 10px;
    margin-right: 10px;
  }

  .ProjectListItemLargePreview {
    width: calc(50% - 50px);
    margin-left: 10px;
    margin-right: 10px;
  }

  .ProjectListItemInnerLargeText {
    font-size: 8px;
    height: 70px;
  }

  .ProjectListItemFooterText {
    font-size: 10px;
  }

  .UserListItemInnerLargeText {
    font-size: 8px;
    height: 18.5px;
  }

  .ReviewListItemInnerLargeText {
    font-size: 9px;
    height: 45px;
  }
  .ReviewListItemDialogLargeText {
    font-size: 12px;
  }
  .RatingsListItemFooterLinkText {
    font-size: 12px;
  }

  .RatingsListItemFooterText svg {
    width: 13px;
    height: 13px;
  }
  .ProjectListItemAvatarSmall {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
  }
}

@media (min-width: 800px) {
  .ProjectListItemAvatar {
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
  }

  .ProjectListItemAvatarMedium {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }

  .ProjectListItemAvatarLarge {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }

  .ProjectListItemHeaderTitle {
    font-size: 15px;
    margin-left: 2px;
  }
  .ProjectListItemHeaderTitleLarge {
    font-size: 17px;
    margin-left: 4px;
  }

  .ProjectListItemHeaderCategory {
    font-size: 11px;
  }

  .ProjectListItemHeaderBadge {
    width: 15px;
    margin-left: 7px;
    margin-top: -1px;
    margin-bottom: -2px;
  }

  .ProjectListItemHeaderBadgeRugged {
    width: 20px;
    margin-left: 7px;
    margin-top: -2px;
    margin-bottom: -1px;
  }
  .ProjectListItemHeaderBadgeLarge {
    width: 20px;
    margin-left: 10px;
  }

  .ProjectListItemHeaderBadgeRuggedLarge {
    width: 24px;
    margin-left: 10px;
  }
  .ProjectListItemInnerLargeText {
    font-size: 10px;
    height: 88px;
  }

  .ProjectListItemFooterText {
    font-size: 12px;
  }
  .UserListItemInnerLargeText {
    font-size: 10px;
    height: 24px;
  }
  .ReviewListItemInnerLargeText {
    font-size: 11px;
    height: 56px;
  }
  .RatingsListItemFooterLinkText {
    font-size: 14px;
  }

  .RatingsListItemFooterText svg {
    width: 15px;
    height: 15px;
  }
  .ProjectListItemAvatarSmall {
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
  }
}

@media (min-width: 1200px) {
  .ProjectListItemSmall {
    width: calc(33% - 40px);
  }

  .ProjectListItemMedium {
    width: calc(33% - 40px);
  }

  .ProjectListItemLarge {
    width: calc(33% - 40px);
  }

  .ProjectListItemSmallPreview {
    width: calc(25% - 50px);
  }

  .ProjectListItemMediumPreview {
    width: calc(25% - 50px);
  }

  .ProjectListItemLargePreview {
    width: calc(25% - 50px);
  }
}

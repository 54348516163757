.Profile {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 100px;
  overflow: hidden;
}

.ProfileHeader {
  width: 90%;
  padding: 0px;
  max-width: 800px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
}
.ProfileHeaderInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ProfileHeaderInnerText {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 15px;
  padding-bottom: 15px;
}

.ProfileAvatar {
  width: 37.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  margin-top: 0px;
  border-radius: 8px;
  overflow: hidden;
}

.ProfileAvatarImg {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProfileAvatarText {
  width: 80%;
  font-weight: 600;
  font-size: 10px;
  margin: 0;
}

.ProfileUsername {
  width: 80%;
  font-weight: 700;
  font-size: 18px;
  margin-top: 14px;
  margin-bottom: 10px;
}

.ProfileEditLinkOuter {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}

.ProfileEditLinkInner {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ProfileEditLinkInnerLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-left: 30px;
}

.ProfileEditLinkText {
  font-weight: 500;
  font-size: 15px;
  text-decoration: none;
}

.ProfileAbout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ProfileAboutAchievements {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.ProfileCountOuter {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 0px;
}

.ProfileAboutInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProfileAboutInnerText {
  margin: 1px;
  font-weight: 500;
  font-size: 11px;
  text-align: left;
  width: 100%;
}

.ProfileAboutEditButtons {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.ProfileAboutEditButtons .ProfileEditLinkInnerLink {
  margin-left: 0px;
}

.ProfileInterests {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ProfileInterestsInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.ProfilePointOuter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin-top: 4px;
  margin-bottom: 10px;
}

.ProfilePointsLinkTitle {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0px;
  width: 80%;
  text-align: center;
  text-decoration: none;
}

.ProfilePointsLinkSub {
  font-size: 20px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 80%;
  text-align: center;
  text-decoration: none;
}

@media (min-width: 350px) {
  .ProfileAvatarText {
    font-size: 10px;
  }
  .ProfileAboutInnerText {
    font-size: 12px;
  }
  .ProfilePointsLinkTitle {
    font-size: 18px;
  }

  .ProfilePointsLinkSub {
    font-size: 24px;
  }
  .ProfileHeaderInnerText {
    margin-left: 20px;
  }
  .ProfileUsername {
    font-size: 22px;
  }
        .ProfileHeaderInner {
          align-items: flex-start;
        }
}

@media (min-width: 550px) {
  .ProfileAvatarText {
    font-size: 12px;
  }
  .ProfileAboutInnerText {
    font-size: 13px;
    margin-top: 10px;
  }
  .ProfilePointsLinkTitle {
    font-size: 21px;
  }

  .ProfilePointsLinkSub {
    font-size: 27px;
  }
  .ProfileHeaderInnerText {
    margin-left: 25px;
  }
  .ProfileUsername {
    font-size: 26px;
  }
    .ProfileHeader {
      width: calc(90% - 30px);
      padding: 15px;
      max-width: 770px;
      border-style: solid;
      border-width: 1px;
    }
        .ProfileAvatar {
          width: 34%;
        }
}

@media (min-width: 700px) {
  .ProfileAvatarText {
    font-size: 13px;
  }
  .ProfilePointsLinkTitle {
    font-size: 24px;
  }

  .ProfilePointsLinkSub {
    font-size: 30px;
  }
  .ProfileAboutInnerText {
    font-size: 14px;
    margin-top: 15px;
  }
  .ProfileHeaderInnerText {
    margin-left: 30px;
  }
  .ProfileUsername {
    font-size: 30px;
  }
}

.MenuDrawer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  padding-top: 100px;
    padding-bottom: 20px;
}

.appMenuBackground {
  width: 12%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.appMenuHeaderLogo {
  width: 12%;
  z-index: 9999;
}

.appMenuLinksOuter {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.appMenuLink {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.appMenuLinkTitle {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.appMenuLinkSub {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
}

.appMenuSocials {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 0px;
}

.appMenuBottomLinks {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 350px) {
  .appMenuSocials {
      width: 50%;

  }
    .appMenuLinkTitle {
      font-size: 17px;
    }
        .appMenuBottomLinks {
          width: 60%;
        }
}

@media (min-width: 500px) {
  .appMenuSocials {
      width: 68%;

  }

        .appMenuLinkTitle {
          font-size: 17px;
        }
                .appMenuBottomLinks {
                  width: 68%;
                }
}



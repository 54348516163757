.ListingPageReviewPreviews {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  padding-bottom: 100px;
}

.ListingPageReviewPreviewsEmpty {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 92%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ListingPageReviewItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ListingPageReviewInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ListingPageReviewInnerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 10px;
}

.ListingPageReviewInnerTitle {
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
  text-align: left;
  flex: 1;
}

.ListingPageReviewInnerLink {
  font-weight: 600;
  font-size: 11px;
  margin: 0px;
  text-align: left;
}

.ListingPageReviewError {
  font-weight: 400;
  font-size: 17px;
  margin: 0;
  text-decoration: none;
}

.ProjectPageReviewsPreviewOuter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.ProjectPageReviewsPreviewTitle {
  font-family: "Kensington", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
}

.ProjectPageReviewsPreviewInner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: -10px;
}

.ProjectPageReviewsPreviewInnerLink {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  width: calc(100% - 22px);
  text-align: left;
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: 350px) {
  .ListingPageReviewInnerTitle {
    font-size: 15px;
  }

  .ListingPageReviewInnerLink {
    font-size: 12px;
  }

  .ListingPageReviewError {
    font-size: 18px;
  }
}

@media (min-width: 550px) {
  .ListingPageReviewInnerTitle {
    font-size: 16px;
  }

  .ListingPageReviewInnerLink {
    font-size: 13px;
  }

  .ListingPageReviewError {
    font-size: 19px;
  }
}

@media (min-width: 701px) {
  .ListingPageReviewInnerTitle {
    font-size: 17px;
  }

  .ListingPageReviewInnerLink {
    font-size: 14px;
  }

  .ListingPageReviewError {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {}
@media (max-width: 1000px) {}

@media (max-width: 800px) {
  
}

@media (max-width: 600px) {
  .ProjectPageReviewsPreviewOuter {
      margin-top: 20px;
    }
  .ProjectPageReviewsPreviewInner {
    width: calc(100%);
    margin-left: 0px;
  }

  .ProjectPageReviewsPreviewInnerLink {
    text-align: center;
  }

  .ProjectPageReviewsPreviewTitle {
    text-align: center;
    font-size: 26px;
  }
}

@media (max-width: 400px) {
  .ProjectPageReviewsPreviewTitle {
      font-size: 22px;
    }
}
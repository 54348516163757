.UserFollowStart {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.UserFollow {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 90px;
}

.UserFollowLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    max-width: 1200px;
    text-decoration: none;
}

.UserFollowLinksIconOuter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 18px;
    position: relative;
}

.UserFollowBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    max-width: 1200px;
}

.UserFollowBodyLoading {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.UserFollowSort {
    display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 90%;
        max-width: 1200px;
        margin-bottom: 5px;
        margin-top: 10px;
}
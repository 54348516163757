.AchievementsListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: calc(50% - 22px);
  border-radius: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
    margin-right: 10px;
}

.AchievementsListItem span {
  font-size: 40px;
}

.AchievementsListItemImg {
  width: 100%;
  aspect-ratio: 1;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
}

.AchievementsListItemTitle {
  font-size: 11px;
  font-weight: 600;
  margin: 0px;
  margin-top: 5px;
  text-align: center;
  width: 90%;
}

.AchievementsListItemSub {
  font-size: 9px;
  font-weight: 400;
  margin: 0px;
  margin-top: -2px;
  text-align: center;
  width: 90%;
}

.AchievementsListItemRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  border-radius: 0px 0px 7px 7px;
  padding-top: 6px;
  padding-bottom: 4px;
}

.AchievementsListItemRowInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  padding: 0px;
  margin: 0px;
}

.AchievementsListItemRowTitle {
  font-size: 8px;
  font-weight: 500;
  margin: 0px;
  margin-top: 0px;
  text-align: center;
  width: 90%;
}

.AchievementsListItemRowSub {
  font-size: 13px;
  font-weight: 600;
  margin: 0px;
  text-align: center;
  width: 90%;
}

@media (min-width: 350px) {
  .AchievementsListItem span {
    font-size: 50px;
  }

  .AchievementsListItemTitle {
    font-size: 12px;
  }

  .AchievementsListItemSub {
    font-size: 10px;
  }

  .AchievementsListItemRowTitle {
    font-size: 9px;
  }

  .AchievementsListItemRowSub {
    font-size: 14px;
  }
}

@media (min-width: 400px) {
    .AchievementsListItem span {
        font-size: 60px;
    }

    .AchievementsListItemTitle {
        font-size: 13px;
    }

    .AchievementsListItemSub {
        font-size: 11px;
    }

    .AchievementsListItemRowTitle {
        font-size: 11px;
    }

    .AchievementsListItemRowSub {
        font-size: 15px;
    }
        .AchievementsListItemRow {
            margin-top: 15px;
            padding-top: 10px;
            padding-bottom: 8px;
        }
}

@media (min-width: 500px) {
    .AchievementsListItem {
        width: calc(33.3333% - 22px);
        margin-bottom: 20px;
    }
}

@media (min-width: 700px) {
    .AchievementsListItem {
        width: calc(25% - 22px);
    }
}
.AdminListDialog {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

}

.AdminListDialogEditButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 0px;
}

.AdminListDialogTitle {
    width: 90%;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;

}

.AdminDialogTitleSub {
    width: 90%;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: -5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.AdminDialogDescription {
    width: 90%;
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0px;
}

.AdminListDialogForms {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.AdminListDialogButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
}

.AdminListDialogConfirmTitle {
    flex: 1;
    font-size: 17px;
    font-weight: 500;
    text-align: Left;
    transition: all 0.2s ease-in-out;
}

.AdminUserDialogInfoOuter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 94%;
    margin-top: 0px;
    margin-bottom: 0px;
}
.AdminUserDialogInfo {
    font-size: 11px;
    margin: 0;
    margin-right: 10px;
    font-weight: 400;
    text-align: left;
    padding: 0;
    margin-top: 10px;
    text-overflow: ellipsis;
    word-break: break-all;
}
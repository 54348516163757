.AdminOuter {
  flex: 1;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 180px;
}
.AdminInner {
  flex: 1;
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
}

.AdminInnerTopRow {
  width: calc(100% + 20px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.AdminInnerTopRowItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px;
}

.AdminInnerTopRowCounter {
  margin: 0;
  margin-left: 1px;
  font-size: 28px;
  font-weight: 600;
  text-align: right;
}

.AdminDescription {
  width: 100%;
  font-size: 15px;
}

.AdminListingSortOuter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}
.AdminListingSortAddOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.AdminListingSortInner {
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.AdminListingsOuter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.AdminListingsLoading {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 15vh;
}

.LoadMoreOuter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.AdminListItemOuter {
  transition: all 0.2s ease-in-out;
  width: 100%;
  margin-bottom: 5px;
}

.AdminListItemSmallOuter {
  transition: all 0.2s ease-in-out;
  width: 100%;
  margin-bottom: 5px;
}

.AdminNewsItemOuter {
  transition: all 0.2s ease-in-out;
  width: 100%;
  margin-bottom: 5px;
  height: 280px;
}

.AdminNewsLinkOuter {
  transition: all 0.2s ease-in-out;
  width: 100%;
  margin-bottom: 0px;
}

.AdminReportsHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}
.AdminReportsHeaderInner {
  width: 24%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.AdminReportsHeaderInnerTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  width: 96%;
  text-align: left;
  margin-left: 4%;
}

.AdminReportsHeaderFilter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.AdminExampleImagesOuter {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.AdminArticlePreviewOverlay {
  position: absolute;
  top: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
  cursor: default;
}

.AdminIssueReportsDialogOuter {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
}

.AdminIssueReportsDialogImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

@media (min-width: 500px) {
  .AdminListItemSmallOuter {
    width: 48%;
  }
}

@media (min-width: 700px) {
  .AdminListItemSmallOuter {
    width: 32%;
  }
}

@media (min-width: 1000px) {
  .AdminListItemSmallOuter {
    width: 24%;
  }

  .AdminNewsItemOuter {
    height: 300px;
  }
}

@media (min-width: 800px) {
  .AdminListItemOuter {
    width: 48%;
  }
  .AdminInner {
      width: 90%;
  }
}

@media (max-width: 800px) {
  .AdminReportsHeaderInner {
    width: 48%;
  }

  .AdminReportsHeaderInnerTitle {
    font-size: 15px;
  }
}

@media (max-width: 350px) {
  .AdminReportsHeaderInner {
    width: 100%;
  }
  .AdminReportsHeaderInnerTitle {
    font-size: 12px;
  }
}

@media (max-width: 1000px) {

  .AdminInnerTopRowCounter {
    font-size: 26px;
  }

  .AdminDescription {
    font-size: 13px;
  }
}

@media (max-width: 800px) {

  .AdminInnerTopRowCounter {
    font-size: 22px;
  }

  .AdminDescription {
    font-size: 10px;
  }
}

@media (max-width: 600px) {

  .AdminInnerTopRowCounter {
    font-size: 18px;
  }
    .AdminDescription {
      font-size: 9px;
    }
}

@media (max-width: 400px) {

  .AdminInnerTopRowCounter {
    font-size: 16px;
  }

  .AdminDescription {
    font-size: 8px;
  }
}
.UserPage {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 100px;
}

.UserPageInner {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.UserPageHeader {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 16px;
}

.UserPageAvatar {
  width: 24%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  aspect-ratio: 1;
  margin-top: 10px;
}

.UserPageAvatarImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UserPageUsername {
  width: 80%;
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 0px;
  margin-top: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.UserPageLastActive {
  width: 80%;
  font-weight: 400;
  font-size: 9px;
  margin-top: 3px;
  margin-bottom: 4px;
  text-align: left;
}

.UserPageEditLinkOuter {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0px;
}

.UserPageEditLinkInner {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.UserPageAbout {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 28px;
  padding-bottom: 25px;
}

.UserPageAboutInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UserPageAboutInnerText {
  margin: 1px;
  font-weight: 500;
  font-size: 13px;
}

.UserPageAboutEditButtons {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.UserPageAboutEditButtons .UserPageEditLinkInnerLink {
  margin-left: 0px;
}

.UserPageInterests {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UserPageInterestsInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.UserPagePointsLink {
  width: 60%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 10px;

  text-decoration: none;
}

.UserPagePointsLinkTitle {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0px;
  width: 80%;
  text-align: center;
  text-decoration: none;
}

.UserPagePointsLinkSub {
  font-size: 20px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 80%;
  text-align: center;
  text-decoration: none;
}

.UserPageDivider {
  width: 100%;
  height: 4px;
}

.ReportUserDialogButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 40px;
}

@media (min-width: 350px) {
  .UserPageAvatar {
    width: 20%;
  }

  .UserPageAboutInnerText {
    font-size: 15px;
  }

  .UserPagePointsLinkTitle {
    font-size: 18px;
  }

  .UserPagePointsLinkSub {
    font-size: 24px;
  }
  .UserPageLastActive {
    font-size: 10px;
  }
  .UserPageUsername {
    font-size: 21px;
  }
}

@media (min-width: 550px) {
  .ProfileAvatar {
    width: 18%;
  }

  .UserPageAboutInnerText {
    font-size: 17px;
  }

  .UserPagePointsLinkTitle {
    font-size: 21px;
  }

  .UserPagePointsLinkSub {
    font-size: 27px;
  }
  .UserPageLastActive {
    font-size: 13px;
    margin-bottom: 12px;
  }
  .UserPageUsername {
    font-size: 26px;
    margin-bottom: 2px;
  }
}

@media (min-width: 700px) {
  .UserPagePointsLinkTitle {
    font-size: 24px;
  }

  .UserPagePointsLinkSub {
    font-size: 30px;
  }
  .UserPageLastActive {
    font-size: 15px;
    margin-bottom: 16px;
  }
  .UserPageUsername {
    font-size: 34px;
    margin-bottom: 5px;
  }
}

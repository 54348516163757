.UserPageV2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 90px;
  padding-bottom: 100px;
}

.UserPageV2Inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
}

.UserPageV2InnerHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 60px);
  margin-bottom: 10px;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.UserPageV2InnerHeaderLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
}

.UserPageV2InnerHeaderTitleOuter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.UserPageV2InnerHeaderTitle {
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  max-width: 90%;
}

.UserPageV2InnerHeaderBadge {
  width: 28px;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: -4px;
  overflow: visible;
}

.UserPageV2InnerHeaderSub {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  margin-top: 5px;
  max-width: 90%;
}

.UserPageV2InnerHeaderDescription {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  white-space: pre-wrap;
  max-width: 90%;
}

.UserPageV2InnerHeaderButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 20px;
}

.UserPageV2InnerHeaderButtonsLink {
  margin: 0px;
  padding: 0px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}
.UserPageV2InnerHeaderButtonsLinkSocial {
  margin-right: 10px;
  margin-bottom: 8px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.UserPageV2InnerHeaderCountOuter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 50%;
  max-width: 400px;
  margin-bottom: 5px;
}

.UserPageV2Body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 5px;
    border-radius: 0px 0px 10px 10px;
}

.UserPageV2BodyTitle {
    font-family: "Kensington", sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 90%;
    text-align: center;
}

.UserPageV2BodyAchievementsOuter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  margin-top: 0px;
}

@media (max-width: 1200px) {
  .UserPageV2InnerHeaderLogo {
    width: 160px;
    height: 160px;
  }

  .UserPageV2InnerHeaderTitle {
    font-size: 24px;
  }

  .UserPageV2InnerHeaderBadge {
    width: 24px;
    margin-bottom: -4px;
  }

  .UserPageV2InnerHeaderSub {
    font-size: 12px;
  }
  .UserPageV2InnerHeaderDescription {
    font-size: 15px;
  }
  .UserPageV2InnerHeaderButtonsLink {
    font-size: 15px;
  }
  .UserPageV2InnerHeaderButtonsLinkSocial {
    margin-right: 9px;
    width: 26px;
    height: 26px;
  }
  .UserPageV2BodyTitle {
      font-size: 24px;
  }
}

@media (max-width: 1000px) {
  .UserPageV2InnerHeaderLogo {
    width: 140px;
    height: 140px;
  }
  .UserPageV2InnerHeaderTitle {
    font-size: 22px;
  }

  .UserPageV2InnerHeaderBadge {
    width: 22px;
    margin-bottom: -3px;
  }

  .UserPageV2InnerHeaderSub {
    font-size: 11px;
  }
  .UserPageV2InnerHeaderDescription {
    font-size: 14px;
  }
   .UserPageV2BodyTitle {
       font-size: 22px;
   }
}

@media (max-width: 800px) {
  .UserPageV2InnerHeaderLogo {
    width: 120px;
    height: 120px;
  }
  .UserPageV2InnerHeaderTitleOuter {
    margin-top: 10px;
  }
  .UserPageV2InnerHeaderTitle {
    font-size: 20px;
  }

  .UserPageV2InnerHeaderBadge {
    width: 20px;
    margin-bottom: -3px;
  }

  .UserPageV2InnerHeaderSub {
    font-size: 10px;
  }
  .UserPageV2InnerHeaderDescription {
    font-size: 13px;
  }
  .UserPageV2InnerHeaderButtonsLink {
    font-size: 14px;
  }
  .UserPageV2InnerHeaderButtonsLinkSocial {
    margin-right: 8px;
    width: 25px;
    height: 25px;
  }
  .UserPageV2InnerHeaderCountOuter {
    width: 60%;
  }
   .UserPageV2BodyTitle {
       font-size: 20px;
   }
}

@media (max-width: 600px) {
    .UserPageV2 {
            padding-bottom: 0px;
        }
  .UserPageV2Inner {
    width: 100%;
    border-radius: 0px;
    border-width: 0px;
    border-bottom-width: 1px;
  }
  .UserPageV2InnerHeaderLogo {
    width: 110px;
    height: 110px;
  }
  .UserPageV2InnerHeaderTitle {
    font-size: 18px;
  }

  .UserPageV2InnerHeaderBadge {
    width: 18px;
    margin-bottom: -2px;
  }

  .UserPageV2InnerHeaderSub {
    font-size: 9px;
  }
  .UserPageV2InnerHeaderDescription {
    font-size: 12px;
  }
  .UserPageV2InnerHeaderButtonsLink {
    font-size: 13px;
    margin-right: 5px;
  }
  .UserPageV2InnerHeaderButtonsLinkSocial {
    margin-right: 7px;
    width: 24px;
    height: 24px;
  }
  .UserPageV2InnerHeaderCountOuter {
    width: 70%;
  }
  .UserPageV2Body {
      border-radius: 0px 0px 0px 0px;
      padding-bottom: 90px;
  }
   .UserPageV2BodyTitle {
       font-size: 18px;
   }
}

@media (max-width: 400px) {
  .UserPageV2InnerHeaderLogo {
    width: 100px;
    height: 100px;
  }
  .UserPageV2InnerHeaderDescription {
    font-size: 11px;
  }
  .UserPageV2InnerHeaderButtonsLink {
    font-size: 12px;
  }
  .UserPageV2InnerHeaderButtonsLinkSocial {
    margin-right: 6px;
    width: 23px;
    height: 23px;
  }
  .UserPageV2InnerHeaderCountOuter {
    width: 80%;
  }
   .UserPageV2BodyTitle {
       font-size: 14px;
   }
}

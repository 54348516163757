.UserList {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: -10px;
  padding-bottom: 100px;
}

.UserListLoading {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.UserListSort {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  margin-bottom: -6px;
}

.UserListSortInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.UserListSortTitle {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
}

.UserListSortDialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.UserListSortDialogInner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
}

.UserListSortDialogInnerText {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
}

.UserListInner {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (min-width: 350px) {
  .UserListItemTitle {
    font-size: 15px;
  }

  .UserListItemSub {
    font-size: 11px;
  }
  .UserListItemAvatar {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 550px) {
  .UserListItemTitle {
    font-size: 16px;
  }

  .UserListItemSub {
    font-size: 12px;
  }
  .UserListItemAvatar {
    width: 34px;
    height: 34px;
  }
}

@media (min-width: 701px) {
  .UserListItemTitle {
    font-size: 17px;
  }

  .UserListItemSub {
    font-size: 13px;
  }
  .UserListItemAvatar {
    width: 38px;
    height: 38px;
  }
}

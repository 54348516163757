.UserListInnerItem {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
}

.UserListItemAvatar {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  border-radius: 8px;
  overflow: hidden;
}

.UserListInnerItemInner {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0px;
  padding: 0px;
  text-decoration: none;
  margin-left: 12px;
}

.UserListItemTitle {
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
  margin: 0;
  margin-bottom: 1px;
}

.UserListItemSub {
  font-size: 10px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  margin: 0;
}

.UserListItemButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}

@media (min-width: 350px) {
  .UserListItemTitle {
    font-size: 15px;
  }

  .UserListItemSub {
    font-size: 12px;
  }
  .UserListItemAvatar {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 550px) {
  .UserListItemTitle {
    font-size: 17px;
  }

  .UserListItemSub {
    font-size: 14px;
  }

  .UserListItemAvatar {
    width: 70px;
    height: 70px;
  }
}

@media (min-width: 701px) {
  .UserListItemTitle {
    font-size: 20px;
  }

  .UserListItemSub {
    font-size: 16px;
  }

  .UserListItemAvatar {
    width: 80px;
    height: 80px;
  }
}

.appBarFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.backdropButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.AuthModal {
  min-width: 300px;
  width: 60vmin;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4ce53;
  border-radius: 30px 30px 0 30px;
  border: 5px solid #1a1a1a;
  box-shadow: 7px 7px 0px #1a1a1a;
  padding-top: 50px;
  padding-bottom: 50px;
}

.AuthIcon {
  width: 30%;
}

.appMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  flex: 1;
  padding-top: 0px;
  padding-bottom: 0px;
}

.appMenuClose {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 4px;
  z-index: 2000;
}

.HeaderLogo {
  width: 100%;
  height: 94px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.HeaderLogoInner {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  min-width: 140px;
  margin-left: calc(5% - 1px);
  cursor: pointer;
}

input,
select {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

@media (min-width: 500px) {
  .appMenu {
    width: 300px;
  }
  .appMenuClose {
    width: 280px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 900px) {
  .HeaderLogoInner {
      margin-left: 18px;
    }
}
.NotificationListener {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  transition: height 0.3s ease;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 9999;
  font-family: "Poppins", sans-serif;
}

.NotificationListenerInner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
  max-width: 800px;
  margin: 0;
    padding: 0;
    margin-top: 10px;
}
.NotificationListenerLogo {
  width: 40px;
  margin-right: 20px;
  margin-left: -10px;
}

.bubbleText {
  position: relative;
  display: inline-block;
  justify-content: center;
  margin: 2px;
  background-color: #fff;
  color: #000;
  box-shadow: 0 -2px #fff, 0 -4px #000, 2px 0 #fff, 2px -2px #000, 4px 0 #000,
    0 2px #fff, 0 4px #000, -2px 0 #fff, -2px 2px #000, -4px 0 #000,
    -2px -2px #000, 2px 2px #000;
  box-sizing: border-box;
  max-width: calc(100% - 60px);
  height: auto;
  font-size: 9px;
  font-weight: 500;
  margin: 0px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  padding: 3px;
  padding-left: 8px;
    padding-right: 8px;
}

.bubbleText::after {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
}

.bubbleText.left::after {
  height: 4px;
  width: 4px;
  top: 12px;
  left: -4px;
  background: white;
  box-shadow: -2px -2px #fff, -2px 0 #fff, -4px 0 #fff, 0 -4px #fff,
    -2px 2px #000, -4px 2px #000, -6px 2px #000, -8px 2px #000, -6px 0 #000,
    -4px -2px #000, -2px -4px #000, 0 -2px #fff;
}

.NotificationListenerText {
  font-size: 9px;
  font-weight: 500;
  margin: 0px;
  text-align: center;
  width: 90%;
}

@media (min-width: 350px) {
  .NotificationListenerText {
    font-size: 10px;
  }
  .bubbleText {
      font-size: 10px;
  }
}

@media (min-width: 450px) {
  .NotificationListenerText {
    font-size: 11px;
  }
  .bubbleText {
      font-size: 11px;
  }
}

@media (min-width: 550px) {
  .NotificationListenerText {
    font-size: 12px;
  }
  .bubbleText {
      font-size: 12px;
  }
}

@media (min-width: 700px) {
  .NotificationListenerText {
    font-size: 13px;
  }
  .bubbleText {
      font-size: 13px;
  }
}

.TimelineItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
.TimelineItemSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px;
  margin: 0px;
}
.TimelineItemSeparator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 13%;
  flex: 1;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
  margin-left: -8%;
  margin-right: -8%;
}
.TimelineItemSeparatorLine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 2px;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
.TimelineItemSeparatorCircle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
.WalletItemTitleOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.WalletItemTitle {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;

  text-overflow: ellipsis;
  overflow: hidden;
}

.WalletItemSub {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  margin-top: 2px;
  margin-bottom: 0px;

  text-overflow: ellipsis;
  overflow: hidden;
}

.WalletListItemInner {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0px;
  padding: 0px;
  text-decoration: none;
  margin-left: 12px;
  overflow: hidden;
}

.WalletListItemOuter {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
  overflow: hidden;
}

.WalletItemDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 24px);
  text-align: left;
  border-radius: 8px;
  padding: 12px;
}

.WalletItemOuterHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.WalletItemImage {
  width: 100%;
}

.WalletItemInnerTitle {
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;

margin-top: 4px;
  margin-bottom: 2px;
}

.WalletItemInnerSub {
  font-size: 11px;
  font-weight: 600;
  margin: 0px;

  text-overflow: ellipsis;
  overflow: hidden;
}
.WalletItemInnerDesc {
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;

word-break: break-all;
}

.WalletItemDivider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.WalletItemInnerFloorPrice {
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.WalletItemLinks {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.WalletItemLinksSvg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  margin-left: 3px;
  margin-right: 3px;
  max-width: 34px;
}

.WalletItemLinksText {
  font-size: 10px;
  font-weight: 600;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.WalletBodyGallery {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 98%;
  flex-wrap: wrap;
  margin-top: 20px;
}

.WalletGalleryItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(48% - 24px);
  text-align: left;
  border-radius: 8px;
  padding: 12px;
    margin-bottom: 16px;
}

.WalletGalleryItemDesc {
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
  height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}

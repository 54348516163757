.IssueReport {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 100px;
}

.IssueReportInner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    padding-top: 20px;
}

.DropzoneOuter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-width: 1px;
    border-radius: 5px;
    border-style: dashed;
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;
    margin-top: 0px;
    min-height: 80px;
}

.DropzoneInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96%
}

.DropzoneImages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
}

.DropzoneImage {
    width: auto;
    height: 100px;
    margin: 10px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    transition: all 0.5s ease-in-out;
}

.IssueReportButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
}
.AchievementsProfile {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: -10px;
  font-family: "Poppins", sans-serif;
  padding-bottom: 120px;
}

.AchievementsProfileInventory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 800px;
  margin-top: 10px;
  border-radius: 8px;
}

.AchievementsProfileInventoryList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 20px);
  padding: 0px;
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.AchievementsInventoryItem {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-family: "Poppins", sans-serif;
}

.AchievementsInventoryItemBadge {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.AchievementsInventoryItemBadge span {
  font-size: 28px;
}

.AchievementsInventoryItemImg {
  width: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0;
}

.AchievementsInventoryItemMiddle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  padding: 0px;
  margin: 0px;
  margin-left: 5px;
}

.AchievementsInventoryItemMiddleTitle {
  font-size: 11px;
  font-weight: 600;
  margin: 0px;
  text-align: left;
  width: 90%;
}

.AchievementsInventoryItemMiddleSub {
  font-size: 9px;
  font-weight: 400;
  margin: 0px;
  margin-top: -2px;
  text-align: left;
  width: 90%;
}

.AchievementsInventoryItemLast {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 30%;
  padding: 0px;
  margin: 0px;
}

.AchievementsInventoryItemLastText {
  font-size: 9px;
  font-weight: 400;
  margin: 0px;
  text-align: right;
  width: 100%;
}

@media (min-width: 350px) {
  .AchievementsInventoryItemBadge span {
    font-size: 32px;
  }

    .AchievementsInventoryItemImg {
      width: 32px;
    }

  .AchievementsInventoryItemMiddleTitle {
    font-size: 12px;
  }
  .AchievementsInventoryItemMiddleSub {
    font-size: 10px;
    margin-top: -1px;
  }
  .AchievementsInventoryItemLastText {
    font-size: 10px;
  }
  .AchievementsInventoryItemMiddle {
    margin-left: 7px;
  }
}

@media (min-width: 450px) {
  .AchievementsInventoryItemBadge span {
    font-size: 36px;
  }

    .AchievementsInventoryItemImg {
      width: 36px;
    }

  .AchievementsInventoryItemMiddleTitle {
    font-size: 13px;
  }

  .AchievementsInventoryItemMiddleSub {
    font-size: 11px;
    margin-top: -1px;
  }

  .AchievementsInventoryItemLastText {
    font-size: 11px;
  }
}

@media (min-width: 600px) {
  .AchievementsInventoryItemBadge span {
    font-size: 42px;
  }

    .AchievementsInventoryItemImg {
      width: 42px;
    }

  .AchievementsInventoryItemMiddleTitle {
    font-size: 14px;
  }

  .AchievementsInventoryItemMiddleSub {
    font-size: 12px;
    margin-top: 0px;
  }

  .AchievementsInventoryItemLastText {
    font-size: 12px;
  }

  .AchievementsInventoryItemMiddle {
    margin-left: 10px;
  }
}

@media (min-width: 800px) {
  .AchievementsInventoryItemBadge span {
    font-size: 48px;
  }
    .AchievementsInventoryItemImg {
      width: 48px;
    }

  .AchievementsInventoryItemMiddleTitle {
    font-size: 15px;
  }

  .AchievementsInventoryItemMiddleSub {
    font-size: 13px;
  }

  .AchievementsInventoryItemLastText {
    font-size: 13px;
  }

  .AchievementsInventoryItemMiddle {
    margin-left: 12px;
  }
}

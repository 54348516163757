.ShortCutWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100% !important;
  width: 100% !important;
  padding: 0px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ShortCutWidgetTitle {
  font-size: 6px;
  font-weight: 600;
  margin: 0px;
  margin-top: 3px;
  text-align: center;
  transition: all 0.3s ease;
}

.ShortCutWidget span {
  font-size: 30px;
  transition: all 0.3s ease;
}

.ListingsWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
}
.ListingsWidgetHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  padding: 0px;
  text-decoration: none;
  padding-top: 15px;
  padding-bottom: 10px;
}

.ListingsWidgetTitle {
  font-size: 16px;
  font-weight: 600;
  margin: 0px;
  text-align: left;
  flex: 1;
}

.ListingsWidgetCategory {
  font-size: 9px;
  font-weight: 500;
  margin: 0px;
  padding: 3px 10px;
  text-align: right;
  margin-left: 10px;
  border-radius: 10px;
}

.ListingsWidgetInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 20px);
  padding: 0px;
  margin: 15px;
  margin-top: 10px;
  text-decoration: none;
}

.CountWidgetTitle {
  font-size: 11px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: center;
  transition: all 0.3s ease;
}

.SingleListingWidget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  padding: 0px;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

.WidgetOverlay {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  left: -1px;
  background-color: rgba(136, 134, 121, 0.7);
  border-radius: 0px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.WidgetOverlay span {
  font-size: 30px;
}

@media (min-width: 350px) {
  .ShortCutWidgetTitle {
    font-size: 8px;
  }

  .ShortCutWidget span {
    font-size: 36px;
  }
  .WidgetOverlay span {
    font-size: 36px;
  }
  .CountWidgetTitle {
    font-size: 14px;
  }
  .ListingsWidgetCategory {
    font-size: 10px;
  }
  .ShortCutWidget {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .SingleListingWidget {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ListingsWidgetTitle {
    font-size: 18px;
  }
}

@media (min-width: 450px) {
  .ShortCutWidgetTitle {
    font-size: 10px;
  }

  .ShortCutWidget span {
    font-size: 44px;
  }
  .WidgetOverlay span {
    font-size: 44px;
  }
  .CountWidgetTitle {
    font-size: 17px;
  }

  .ListingsWidgetTitle {
    font-size: 20px;
  }
  .ListingsWidgetCategory {
    font-size: 11px;
  }
  .ShortCutWidget {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .SingleListingWidget {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 550px) {
  .WidgetOverlay span {
    font-size: 50px;
  }
  .CountWidgetTitle {
    font-size: 18px;
  }

  .ListingsWidgetTitle {
    font-size: 22px;
  }
  .ListingsWidgetCategory {
    font-size: 12px;
  }
}

@media (min-width: 600px) {
  .ShortCutWidgetTitle {
    font-size: 7px;
  }

  .ShortCutWidget span {
    font-size: 32px;
  }
  .CountWidgetTitle {
    font-size: 12px;
  }

  .ListingsWidgetTitle {
    font-size: 15px;
  }
  .ListingsWidgetCategory {
    font-size: 9px;
  }
}

@media (min-width: 700px) {
  .ShortCutWidgetTitle {
    font-size: 9px;
  }

  .ShortCutWidget span {
    font-size: 36px;
  }
  .WidgetOverlay span {
    font-size: 56px;
  }
  .CountWidgetTitle {
    font-size: 14px;
  }
  .ListingsWidgetTitle {
    font-size: 17px;
  }
  .ListingsWidgetCategory {
    font-size: 10px;
  }
}

@media (min-width: 850px) {
  .ShortCutWidgetTitle {
    font-size: 10px;
    margin-top: 10px;
  }

  .ShortCutWidget span {
    font-size: 38px;
  }
  .WidgetOverlay span {
    font-size: 62px;
  }
  .CountWidgetTitle {
    font-size: 18px;
  }
  .ListingsWidgetTitle {
    font-size: 19px;
  }
  .ListingsWidgetCategory {
    font-size: 12px;
  }
}

.ProfileSettings {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 120px;
  font-family: "Poppins", sans-serif;
}

.ProfileSettingsTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 6px;
  margin-top: 10px;
  text-align: left;
  width: 88%;
  max-width: 800px;
}

.ProfileSettingsSub {
  font-size: 8px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
  margin-bottom: 15px;
  width: 88%;
  max-width: 800px;
}

.ProfileSettingsSubTitle {
  font-size: 13px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
  margin-bottom: 10px;
  width: 88%;
  max-width: 800px;
}

.ProfileDivider {
  width: 100%;
  height: 4px;
  margin: 0px;
}

@media (min-width: 350px) {
  
.ProfileSettingsTitle {
  font-size: 20px;
}

.ProfileSettingsSub {
  font-size: 10px;
}
}

@media (min-width: 450px) {

  .ProfileSettingsTitle {
    font-size: 22px;
  }

  .ProfileSettingsSub {
    font-size: 12px;
  }
}

@media (min-width: 550px) {

  .ProfileSettingsTitle {
    font-size: 23px;
  }

  .ProfileSettingsSub {
    font-size: 13px;
  }
    .ProfileSettingsSubTitle {
      font-size: 14px;
    }
}

@media (min-width: 700px) {

  .ProfileSettingsTitle {
    font-size: 24px;
  }

  .ProfileSettingsSub {
    font-size: 14px;
  }
    .ProfileSettingsSubTitle {
      font-size: 16px;
    }
}
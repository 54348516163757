.AchievementsList {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: -10px;
    font-family: "Poppins", sans-serif;
    padding-bottom: 120px;
}

.AchievementsListInner {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    width: calc(90% + 20px);
    max-width: 820px;
    margin-top: 15px;
    flex-wrap: wrap;
    margin-left: 1px;
}

.AchievementsListLoading {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 800px;
}
.ProjectPageRatingGauge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.ProjectPageRatingGaugeErrorText {
  width: 90%;
  font-size: 13px;
}

.ProjectPageRatingGaugeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.ProjectPageRatingGaugeContainerGauge {
  width: 80%;
}

.ProjectPageRatingGaugeContainerTitle {
  font-size: 16px;
  margin-top: -5px;
  width: 70%;
}

.ProjectPageRatingGaugeContainerFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin-top: 0px;
}

.ProjectPageRatingGaugeContainerFooterItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .ProjectPageRatingGaugeContainerFooterItem {
    font-size: 18px;
  }
  .ProjectPageRatingGaugeContainerTitle {
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  .ProjectPageRatingGaugeContainerFooterItem {
    font-size: 14px;
  }
  .ProjectPageRatingGaugeContainerTitle {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .ProjectPageRatingGaugeContainerFooterItem {
    font-size: 12px;
  }
  .ProjectPageRatingGaugeContainerTitle {
    font-size: 10px;
  }
}

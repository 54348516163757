.AdminLogoUpload {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.AdminLogoUploadTitle {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  text-align: Left;
  transition: all 0.2s ease-in-out;
  margin-left: 15px;
}

.AdminAddUserPreReg {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.AdminAddUserPreRegInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.AdminAddUserPreTitle {
  width: 80%;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-left: 5%;
}

.AdminAddUserPreRegForms {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.AdminAddUserPreRegFormsInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AdminAddUserPreRegForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
}
.AdminAddUserPreRegFormAlt {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
}

.AdminAddUserPreRegInnerDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.AdminAddUserPreRegButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}

.AdminNewsletterAddEmail {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.AdminArticlePreview {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 0px;
  padding-bottom: 20px;
}

.AdminArticlePreviewLarge {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.AdminArticlePreviewLargeTextOuter {
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5px;
  margin-bottom: 10px;
}

.AdminArticlePreviewLargeImgOuter {
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
}

.AdminArticlePreviewLargeTitle {
  width: 90%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0px;
  font-family: 'Kensington', sans-serif;
  font-style: normal;
  margin-top: 10px;
  letter-spacing: 1px;
}

.AdminArticlePreviewLargeSub {
  width: 90%;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0px;
}

.AdminArticlePreviewLargeText {
  width: 90%;
  font-size: 8px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    /* number of lines to show */
    line-clamp: 5;
    -webkit-box-orient: vertical;
}

.AdminArticlePreviewLargeImg {
  width: 95%;
  height: 95%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  object-fit: cover;
}

.AdminArticlePreviewButtons {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3px;
}

.AdminArticlePreviewSmall {
  width: 98%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AdminArticlePreviewSmallInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.AdminArticlePreviewSmallTextOuter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.AdminArticlePreviewSmallImgOuter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AdminArticlePreviewSmallSub {
  width: 90%;
  font-size: 11px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0px;
}

.AdminArticlePreviewSmallText {
  width: 90%;
  font-size: 9px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  /* number of lines to show */
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.AdminArticlePreviewSmallImg {
  width: 90%;
  aspect-ratio: 16/10;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  object-fit: cover;
}

.AdminArticlePreviewSmallButton {
  width: 97%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  padding: 5px;
  min-height: 30px;
  text-transform: uppercase;
}

.AdminArticlePreviewPickedListing {
  width: 98%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 600;
}

@media (min-width: 700px) {
  .AdminAddUserPreRegInner {
    flex-direction: row;
  }
  .AdminAddUserPreRegFormsInner {
      flex-direction: row;
      justify-content: space-between;
  }
  .AdminAddUserPreRegForm {
      width: 49%;
      margin-bottom: 0px;
  }

  .AdminAddUserPreRegFormAlt {
      width: 48%;
      margin-bottom: 0px;
  }
  .AdminAddUserPreRegInnerDiv {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0px;
    width: unset;
    margin-left: 20px;
  }

.AdminArticlePreviewLargeTitle {
  font-size: 24px;
}

.AdminArticlePreviewLargeSub {
  font-size: 13px;
}

.AdminArticlePreviewLargeText {
  font-size: 11px;
  -webkit-line-clamp: 7;
  /* number of lines to show */
  line-clamp: 7;
}
}

@media (min-width: 1000px) {
  .AdminListItemOuter {
    width: 31%;
  }

    .AdminArticlePreviewLargeTitle {
      font-size: 30px;
    }
  
    .AdminArticlePreviewLargeSub {
      font-size: 15px;
    }
  
    .AdminArticlePreviewLargeText {
      font-size: 13px;
      -webkit-line-clamp: 8;
      /* number of lines to show */
      line-clamp: 8;
    }
}

.RatingItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 97%;
  overflow: hidden;
}

.RatingItemRating {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.RatingItemRatingText {
  font-size: 8px;
  font-weight: 500;
  margin: 0px;
  margin-right: 5px;
  white-space: nowrap;
  text-decoration: none;
}

.RatingItemRating svg {
  width: 10px;
}

.AddReviewItemOuter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 17px);
  padding: 10px;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  max-width: 1180px;
}

.AddReviewItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  padding: 0px;
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.AddReviewItemInner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  transition: all 0.5s ease-in-out;
}

.AddReviewItemInnerText {
  font-size: 10px;
  font-weight: 600;
}

.AddReviewItemNewReviewOuter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px;
  margin: 0px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.AddReviewItemNewReviewButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0px;
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.5s ease-in-out;
}

.ProjectListRatingItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.ProjectListRatingItemInner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.ProjectListRatingItemRating {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.ProjectListRatingItemRatingText {
  font-size: 8px;
  font-weight: 500;
  margin: 0px;
  margin-right: 3px;
  margin-left: 3px;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
}
.ProjectListRatingItemRatingTextAlt {
  font-size: 8px;
  font-weight: 500;
  margin: 0px;
  margin-right: 3px;
  margin-left: 3px;
  white-space: nowrap;
  text-decoration: none;
  text-align: left;
  margin-top: 6.5px;
  margin-bottom: 4px;
}

.ProjectListRatingItemRating svg {
  width: 7px;
  margin-top: 1px;
}

@media (min-width: 350px) {
  .RatingItemRatingText {
    font-size: 9px;
  }
  .AddReviewItemInnerText {
    font-size: 11px;
  }
}

@media (min-width: 550px) {
  .RatingItemRatingText {
    font-size: 11px;
  }
  .AddReviewItemInnerText {
    font-size: 12px;
  }
}

@media (min-width: 701px) {
  .RatingItemRatingText {
    font-size: 12px;
  }

  .AddReviewItemInnerText {
    font-size: 13px;
  }
}

@media (min-width: 400px) {
  .ProjectListRatingItemRatingText {
    font-size: 10px;
  }
  .ProjectListRatingItemRatingTextAlt {
    font-size: 10px;
    margin-top: 5.5px;
    margin-bottom: 3px;
  }

  .ProjectListRatingItemRating svg {
    width: 9px;
    margin-top: 1px;
  }
}

@media (min-width: 600px) {
  .ProjectListRatingItemRatingText {
    font-size: 8px;
  }

    .ProjectListRatingItemRatingTextAlt {
      font-size: 8px;
      margin-top: 6.5px;
      margin-bottom: 4px;
    }

  .ProjectListRatingItemRating svg {
    width: 7px;
    margin-top: 1px;
  }
  
}

@media (min-width: 800px) {
  .ProjectListRatingItemRatingText {
    font-size: 10px;
  }

  .ProjectListRatingItemRatingTextAlt {
    font-size: 10px;
    margin-top: 5.5px;
    margin-bottom: 3px;
  }

  .ProjectListRatingItemRating svg {
    width: 9px;
    margin-top: 1px;
  }
}
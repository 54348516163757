.LoadingLogo {
  width: 100%;
}

.LoadingLogoIcon {
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffd379;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #39393c;
}

.footerText {
  margin: 0;
  font-weight: 300;
  text-align: center;
  font-size: 8px;
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.socialLink {
  width: 14%;
}

.LazyLoadImgLoading {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
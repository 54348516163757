.Explore {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 120px;
  margin-top: 0px;
  font-family: "Poppins", sans-serif;
}

.ExploreNewListingBox {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ExploreListInner {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0px;
  flex-wrap: wrap;
}

.ExploreSort {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  margin-bottom: 6px;
}

.ExploreSortInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.ExploreSortTitle {
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
}

@media (min-width: 350px) {
  .ExploreSortTitle {
    font-size: 12px;
  }
}

@media (min-width: 550px) {
  .ExploreSortTitle {
    font-size: 13px;
  }
}

@media (min-width: 701px) {
  .ExploreSortTitle {
    font-size: 14px;
  }
}

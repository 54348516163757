.ListingsWidgetItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
}

.ListingsWidgetItemAvatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 11%;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
  overflow: hidden;
}

.UserWidgetItemAvatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 11%;
  aspect-ratio: 1;
  border-radius: 5px;
  margin-right: 10px;
  background-color: transparent;
  overflow: hidden;
}

.ListingsWidgetItemInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(88% - 10px);
}

.ListingsWidgetItemTitleOuter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ListingsWidgetItemTitle {
  font-size: 11px;
  font-weight: 600;
  margin: 0px;
  text-align: left;
  max-width: 96%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ListingsWidgetItemTitleLast {
  font-size: 9px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
  margin-bottom: 1px;
  margin-left: 3px;
}

.ListingsWidgetItemSub {
  font-size: 9px;
  font-weight: 400;
  margin: 0px;
  margin-left: 1px;
  text-align: left;
}

@media (min-width: 350px) {
  .ListingsWidgetItemTitle {
    font-size: 12px;
  }

  .ListingsWidgetItemTitleLast {
    font-size: 10px;
  }

  .ListingsWidgetItemSub {
    font-size: 10px;
  }
}

@media (min-width: 450px) {
  .ListingsWidgetItemTitle {
    font-size: 14px;
  }

  .ListingsWidgetItemTitleLast {
    font-size: 12px;
  }

  .ListingsWidgetItemSub {
    font-size: 12px;
  }
}

@media (min-width: 550px) {
  .ListingsWidgetItemTitle {
    font-size: 15px;
  }

  .ListingsWidgetItemTitleLast {
    font-size: 13px;
  }

  .ListingsWidgetItemSub {
    font-size: 13px;
  }
}

@media (min-width: 600px) {
  .ListingsWidgetItemTitle {
    font-size: 11px;
  }

  .ListingsWidgetItemTitleLast {
    font-size: 9px;
  }

  .ListingsWidgetItemSub {
    font-size: 9px;
  }
}

@media (min-width: 700px) {
  .ListingsWidgetItemTitle {
    font-size: 12px;
  }

  .ListingsWidgetItemTitleLast {
    font-size: 10px;
  }

  .ListingsWidgetItemSub {
    font-size: 10px;
  }
}

@media (min-width: 850px) {
  .ListingsWidgetItemTitle {
    font-size: 14px;
  }

  .ListingsWidgetItemTitleLast {
    font-size: 12px;
  }

  .ListingsWidgetItemSub {
    font-size: 12px;
  }
}
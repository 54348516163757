.UserReviewsItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-top: 15px;
  overflow: scroll;
}

.ReviewsItemOverlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
  top: 0;
  left: 0;
  padding: 15px;
  border-radius: 8px;
  transition: all 0.5s ease;
}

.ReviewsItemUserHeader {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: calc(100% - 30px);
  margin: 15px;
  margin-top: 0px;
  
}

.ReviewsItemUserHeaderAvatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  overflow: hidden;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.ReviewsItemUserHeaderAvatarImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ReviewsItemUserHeaderInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  margin-left: 15px;
  flex: 1;
  text-decoration: none;
}

.ReviewsItemUserHeaderInnerName {
  font-weight: 500;
  font-size: 11px;
  margin: 0px;
  width: 100%;
  text-align: left;
  text-decoration: none;
}

.ReviewsItemUserHeaderInnerSub {
  font-weight: 300;
  font-size: 9px;
  margin: 0px;
  margin-top: 2px;
  margin-bottom: 0px;
  width: 100%;
  text-align: left;
  text-decoration: none;
}

.ReviewsItemInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 30px);
  margin: 15px;
  margin-top: 0px;
  white-space: pre-wrap;
}

.RatingItemInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 30px);
  margin: 15px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.UserReviewsItemTitle {
  font-weight: 500;
  font-size: 14px;
  margin: 0px;
  margin-top: 4px;
  width: 100%;
  text-align: left;
}

.UserReviewsItemSub {
  font-weight: 500;
  font-size: 11px;
  margin: 0px;
  margin-top: 0px;
  width: 100%;
  text-align: left;
}

.ReviewsItemRatingOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;

}

.UserReviewsItemText {
  font-weight: 400;
  font-size: 12px;
  margin: 0px;
  margin-top: 4px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
}

.UserRatingItemBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 30px);
    margin: 15px;
    margin-top: 10px;
  margin-bottom: 10px;
}

.UserRatingItemBottomText {
  font-weight: 500;
  font-size: 9px;
  margin: 0px;
  text-align: left;
  margin-left: 5px;
}

.NewReviewItemOuter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 17px);
  padding: 10px;
  margin: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  max-width: 1180px;
}

.NewReviewItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  padding: 0px;
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.NewReviewItemInner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  transition: all 0.5s ease-in-out;
  width: 49%;
}

.NewReviewItemInnerText {
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
}
.NewReviewItemBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
  padding: 0px;
  margin: 0px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  position: relative;
}

.NewReviewItemBodyRating {
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
}

.NewReviewItemBodyRatingInner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}

.NewReviewItemNewReviewOuter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px;
  margin: 0px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.NewReviewItemBodyButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  padding: 0px;
  margin: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.NewReviewItemButtonText {
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
}

.NewReviewItemBodyReview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

@media (min-width: 350px) {
  .UserReviewsItemTitle {
    font-size: 14px;
  }

  .UserReviewsItemSub {
    font-size: 13px;
  }
.ReviewsItemUserHeaderAvatar {
  width: 35px;
  height: 35px;
}

.ReviewsItemUserHeaderInnerName {
  font-size: 12px;
}

.ReviewsItemUserHeaderInnerSub {
  font-size: 10px;
}

  .UserReviewsItemText {
    font-size: 13px;
  }

  .UserReviewsItemDate {
    font-size: 12px;
  }

  .UserRatingItemBottomText {
    font-size: 11px;
  }
}

@media (min-width: 550px) {
  .UserReviewsItemTitle {
    font-size: 15px;
  }

  .UserReviewsItemSub {
    font-size: 14px;
  }

    .ReviewsItemUserHeaderAvatar {
      width: 40px;
      height: 40px;
    }
  
    .ReviewsItemUserHeaderInnerName {
      font-size: 13px;
    }
  
    .ReviewsItemUserHeaderInnerSub {
      font-size: 11px;
    }

  .UserReviewsItemText {
    font-size: 14px;
  }

  .UserReviewsItemDate {
    font-size: 13px;
  }

  .UserRatingItemBottomText {
    font-size: 12px;
  }
}

@media (min-width: 700px) {
  .UserReviewsItemTitle {
    font-size: 16px;
  }

  .UserReviewsItemSub {
    font-size: 15px;
  }

    .ReviewsItemUserHeaderAvatar {
      width: 45px;
      height: 45px;
    }
  
    .ReviewsItemUserHeaderInnerName {
      font-size: 14px;
    }
  
    .ReviewsItemUserHeaderInnerSub {
      font-size: 12px;
    }

  .UserReviewsItemText {
    font-size: 15px;
  }

  .UserReviewsItemDate {
    font-size: 14px;
  }

  .UserRatingItemBottomText {
    font-size: 13px;
  }
}

@media (max-width: 1200px) {

  
}

@media (max-width: 1000px) {

  
}

@media (max-width: 800px) {

  .NewReviewItemButtonText {
    font-size: 11px;
  }
}

@media (max-width: 600px) {

  .NewReviewItemButtonText {
    font-size: 10px;
  }
}

@media (max-width: 400px) {
  .NewReviewItemBody {
      width: 95%;
    }
        .NewReviewItemButtonText {
          font-size: 9px;
        }
}
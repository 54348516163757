.ProjectPageV2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 90px;
  padding-bottom: 100px;
}

.ProjectPageV2Inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
}

.ProjectPageV2Header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 30px;
}

.ProjectPageV2HeaderTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: calc(100% - 60px);
  margin-bottom: 20px;
}

.ProjectPageV2HeaderTopLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 48%;
}

.ProjectPageV2HeaderTopLeftHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.ProjectPageV2HeaderTopLeftHeaderLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
}

.ProjectPageV2HeaderTopLeftHeaderText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
  flex: 1;
  text-align: left;
}

.ProjectPageV2HeaderTopLeftHeaderTitle {
  font-size: 30px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
}

.ProjectPageV2HeaderTopLeftHeaderSub {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  margin-top: 5px;
}

.ProjectPageV2HeaderTopLeftBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.ProjectPageV2HeaderTopLeftBodyLinks {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.ProjectPageV2HeaderTopLeftBodyLink {
  margin-right: 10px;
  margin-bottom: 8px;
  padding: 5px 0px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  width: 140px;
}

.ProjectPageV2HeaderTopLeftBodyLinkSocial {
  margin-right: 10px;
  margin-bottom: 8px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ProjectPageV2HeaderTopLeftBodyDescription {
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.ProjectPageV2HeaderTopLeftBodyTagsInner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}

.ProjectPageV2HeaderTopLeftBodyTag {
  margin: 0;
  margin-right: 10px;
  margin-bottom: 7px;
  padding: 3px 6px;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
}

.ProjectPageV2HeaderTopLeftBodyDate {
  font-size: 12px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 0px;
}

.ProjectPageV2HeaderTopRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 48%;
}

.ProjectPageV2HeaderBottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 60px);
  margin-bottom: 0px;
  margin-top: 10px;
}

.ProjectPageV2Body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  border-radius: 0px 0px 10px 10px;
}

.ProjectPageV2BodyMenuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ProjectPageV2BodyMenuBarItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 0px;
  margin: 0px;
  cursor: pointer;
}

.ProjectPageV2BodyInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 60px);
  padding-top: 10px;
}

@media (max-width: 1200px) {
  .ProjectPageV2HeaderTopLeftHeaderLogo {
    width: 120px;
    height: 120px;
  }
  .ProjectPageV2HeaderTopLeftHeaderTitle {
    font-size: 26px;
  }
  .ProjectPageV2HeaderTopLeftHeaderSub {
    font-size: 16px;
  }
  .ProjectPageV2HeaderTopLeftBodyLink {
    font-size: 13px;
    width: 120px;
  }
  .ProjectPageV2HeaderTopLeftBodyLinkSocial {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
  .ProjectPageV2HeaderTopLeftBodyDescription {
    font-size: 15px;
  }
  .ProjectPageV2HeaderTopLeftBodyTag {
    margin-right: 8px;
    font-size: 12px;
  }
  .ProjectPageV2HeaderTopLeftBodyDate {
    font-size: 11px;
  }
}

@media (max-width: 1000px) {
  .ProjectPageV2HeaderTopLeftHeaderLogo {
    width: 100px;
    height: 100px;
  }
  .ProjectPageV2Header {
    padding-top: 20px;
  }

  .ProjectPageV2HeaderTop {
    width: calc(100% - 40px);
  }
  .ProjectPageV2HeaderTopLeftHeaderText {
    margin-left: 15px;
  }
  .ProjectPageV2HeaderTopLeftHeaderTitle {
    font-size: 24px;
  }
  .ProjectPageV2HeaderTopLeftHeaderSub {
    font-size: 14px;
  }
  .ProjectPageV2HeaderTopLeftBodyLink {
    font-size: 10px;
    width: 100px;
  }
  .ProjectPageV2HeaderTopLeftBodyLinkSocial {
    margin-right: 7px;
    width: 21px;
    height: 21px;
  }
  .ProjectPageV2HeaderTopLeftBodyDescription {
    font-size: 14px;
  }
  .ProjectPageV2HeaderTopLeftBodyTag {
    margin-right: 7px;
    font-size: 11px;
  }
  .ProjectPageV2HeaderTopLeftBodyDate {
    font-size: 10px;
  }
  .ProjectPageV2HeaderBottom {
    width: calc(100% - 40px);
  }

  .ProjectPageV2BodyInner {
      width: calc(100% - 40px);
  }
}

@media (max-width: 800px) {
  .ProjectPageV2HeaderTopLeftHeader {
    margin-bottom: 20px;
  }
  .ProjectPageV2HeaderTopLeftHeaderLogo {
    width: 90px;
    height: 90px;
  }
  .ProjectPageV2HeaderTopLeftHeaderTitle {
    font-size: 22px;
  }
  .ProjectPageV2HeaderTopLeftHeaderSub {
    font-size: 12px;
  }
  .ProjectPageV2HeaderTopLeftBodyLink {
    font-size: 9px;
    width: 90px;
  }
  .ProjectPageV2HeaderTopLeftBodyLinkSocial {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }
  .ProjectPageV2HeaderTopLeftBodyDescription {
    font-size: 13px;
  }
  .ProjectPageV2HeaderTopLeftBodyTag {
    margin-right: 6px;
    font-size: 10px;
  }
  .ProjectPageV2HeaderTopLeftBodyDate {
    font-size: 9px;
  }
}

@media (max-width: 600px) {
    .ProjectPageV2 {
            padding-bottom: 0px;
  padding-top: 80px;
        }
    .ProjectPageV2Inner {
            width: 100%;
            border-radius: 0px;
            border-width: 0px;
            border-bottom-width: 1px;
        }
  .ProjectPageV2HeaderTop {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  .ProjectPageV2HeaderTopLeft {
    width: 100%;
    margin-bottom: 20px;
  }
  .ProjectPageV2HeaderTopRight {
    width: 100%;
    margin-bottom: 0px;
  }

  .ProjectPageV2HeaderTopLeftHeaderLogo {
    width: 100px;
    height: 100px;
  }
  .ProjectPageV2HeaderTopLeftHeaderTitle {
    font-size: 26px;
  }
  .ProjectPageV2HeaderTopLeftHeaderSub {
    font-size: 14px;
  }
  .ProjectPageV2HeaderTopLeftHeaderText {
    margin-left: 10px;
  }
  .ProjectPageV2HeaderTopLeftBodyLink {
    font-size: 9px;
    width: 100px;
  }
  .ProjectPageV2HeaderTopLeftBodyLinkSocial {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
  .ProjectPageV2HeaderTopLeftBodyDescription {
    font-size: 13px;
  }
  .ProjectPageV2HeaderTopLeftBodyTag {
    margin-right: 5px;
    font-size: 10px;
  }
  .ProjectPageV2HeaderTopLeftBodyDate {
    font-size: 10px;
  }
  .ProjectPageV2HeaderBottom {
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 100%;
  }
  .ProjectPageV2Body {
      border-radius: 0px 0px 0px 0px;
      padding-bottom: 90px;
  }
  .ProjectPageV2BodyInner {
      padding-top: 30px;
  }
}

@media (max-width: 400px) {
  .ProjectPageV2HeaderTopLeftHeaderLogo {
    width: 80px;
    height: 80px;
  }
  .ProjectPageV2HeaderTopLeftHeaderTitle {
    font-size: 22px;
  }
  .ProjectPageV2HeaderTopLeftHeaderSub {
    font-size: 11px;
  }
  .ProjectPageV2HeaderTopLeftBodyLink {
    font-size: 8px;
    width: 80px;
  }
  .ProjectPageV2HeaderTopLeftBodyLinkSocial {
    width: 19px;
    height: 19px;
  }
  .ProjectPageV2HeaderTopLeftBodyDescription {
    font-size: 11px;
  }
  .ProjectPageV2HeaderTopLeftBodyTag {
    font-size: 8px;
  }
  .ProjectPageV2HeaderTopLeftBodyDate {
    font-size: 8px;
  }
}

.ListingsItem {
  width: calc(100% - 24px);
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 15px;
  padding: 12px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.ListingItemAvatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
}

.ListingItemDivider {
  flex: 1;
}

.ListingsItemInner {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0px;
  text-decoration: none;
  margin-left: 10px;
  overflow: hidden;
}

.ListingsItemInnerHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0;
  margin-top: -1px;
  overflow: hidden;
  max-width: 95%;
}

.ListingsItemTitle {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  margin: 0;
}

.ListingsItemTitleSub {
  font-size: 80%;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  margin: 0;
  margin-left: 4px;
  margin-bottom: 1px;
}

.ListingsItemDesc {
  font-size: 9px;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
  margin: 0;
  width: 95%;
  text-decoration: none;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.ListingsItemButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 0px;
  padding: 0px;
}

.ListingsItemButtonsLink {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0px;
  padding: 0px;
}


@media (min-width: 350px) {
  .ListingsItemTitle {
    font-size: 13px;
  }

  .ListingsItemDesc {
    font-size: 10px;
  }

  .UserListItemAvatar {
    width: 30px;
    height: 30px;
  }
  .ListingItemAvatar {
    width: 75px;
    height: 75px;
  }
}

@media (min-width: 550px) {
  .ListingsItemTitle {
    font-size: 14px;
  }

  .ListingsItemDesc {
    font-size: 11px;
  }

  .UserListItemAvatar {
    width: 34px;
    height: 34px;
  }
  .ListingItemAvatar {
    width: 85px;
    height: 85px;
  }
}

@media (min-width: 701px) {
  .ListingsItemTitle {
    font-size: 15px;
  }

  .ListingsItemDesc {
    font-size: 12px;
  }

  .UserListItemAvatar {
    width: 38px;
    height: 38px;
  }
  .ListingItemAvatar {
    width: 95px;
    height: 95px;
  }
}

.AuthVerify {
  flex: 1;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginViewOuter {
  flex: 1;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginView {
  flex: 1;
  width: 76%;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginViewAlt {
  flex: 1;
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
}

.AuthIcon {
  width: 20%;
  margin-bottom: 5px;
  margin-top: 50px;
}

.AuthIconStart {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.AuthStartButtons {
  width: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}

.AuthStartButtonsInner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-top: 34px;
  padding-bottom: 30px;
  transition: transform 0.8s ease-in-out;
  min-height: 220px;
}

.AuthStartButtonsInnerAlt {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 100px;
  margin-top: 20px;
  padding-top: 34px;
  padding-bottom: 30px;
  transition: transform 0.8s ease-in-out;
  position: absolute;
  min-height: 250px;
}

.AuthStartFooter {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 25px;
  margin-top: 25px;
}

.AuthText {
  flex: 1;
  max-width: 86%;
  font-size: 13px;
  margin: 0;
  margin-bottom: 0px;
  text-align: center;
  margin-top: 6px;
}

.InputText {
  width: 99%;
  font-size: 12px;
  margin: 0;
  margin-top: 3px;
  text-align: left;
  min-height: 14px;
}

.InputTextAlt {
  width: 79%;
  font-size: 12px;
  margin: 0;
  margin-top: 3px;
  text-align: left;
  min-height: 14px;
}

.ErrorText {
  width: 80%;
  font-size: 90%;
  margin: 0;
  margin-top: 10px;
  text-align: center;
  height: 25px;
}

.SuccessTitle {
  width: 80%;
  font-size: 18px;
  margin: 0;
  margin-top: 10px;
  font-weight: 600;
}

.ButtonTitle {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}

.SuccessText {
  width: 80%;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
}

.AuthModalInput {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin-top: 14px;
}

.textfieldOuter {
  width: 86%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.textfieldOuter input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.textfieldOuter input[type=number] {
  -moz-appearance: textfield;
}

.AuthButtonIcon {
  width: 8%;
  height: auto;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input,
select {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.AuthVerifyIcon {
  width: 20%;
  max-width: 200px;
  opacity: 1;
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

@media (min-width: 500px) {
  .AuthText {
      font-size: 14px;
    }
}

.BottomNavigatorConfigurator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 88%;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
}

.BottomNavigatorPreview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 102%;
  max-width: 820px;
  border-radius: 8px;
  min-height: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.BottomNavigatorPreviewItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.BottomNavigatorPicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}

.BottomNavigatorPickerExpand {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.BottomNavigatorPickerButton {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  width: calc(100% - 2px);
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: transparent;
}

.BottomNavigatorPreviewItemLabel {
  font-size: 13px;
  font-weight: 600;
  width: calc(100% - 60px);
  text-align: center;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.BottomNavigatorPickerButtonEnd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin: 0px;
  border-radius: 0px 3px 3px 0px;
  aspect-ratio: 1;
}

.BottomNavigatorPickerSelected {
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 5px;
  margin: 0px;
}

.BottomNavigatorSaving {
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
  width: 90%;
  text-align: center;
  min-height: 20px;
}

.PrivacyConfig {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 30vh;
}

.PrivacyConfigInner {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}

.PrivacyConfigRadio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 10%;
}

.PrivacyConfigLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
}

.PrivacyConfigLabelTitle {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 2%;
  flex: 1;
  text-align: left;
}

.PrivacyConfigLabelSub {
  font-size: 12px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 2%;
  flex: 1;
  text-align: left;
}

.SocialConfig {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 88%;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
}

.SocialConfigInner {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

.SocialConfigButtonText {
  font-size: 11px;
  font-weight: 600;
}

.SocialConfigTextField {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
}

.SocialConfigTextFieldLabel {
  font-size: 10px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
  width: 70px;
  margin-right: 10px;
}

.SocialConfigHelperText {
  font-size: 8px;
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 4px;
  text-align: left;
  padding-left: 5px;
  padding-right: 20px;
  width: calc(100% - 25px);
  height: 11px;
}

.UsernameTitle {
  font-size: 8px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 0;
  text-align: left;
  width: 100%;
}

.PersonalSettingsTitle {
  font-size: 11px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 8px;
  margin-left: 0;
  text-align: left;
  width: 100%;
}

.PersonalSettingsRadios {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.PersonalSettingsInputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  align-self: flex-start;
}

.DeleteAccount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 88%;
  max-width: 800px;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ReferralLoading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 88%;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
}

.ReferralViewer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 88%;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
}

.ReferralsCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 88%;
    max-width: 800px;
}

.ReferralsCountText {
  font-size: 10px;
  font-weight: 500;
  margin-right: 15px;
  flex: 1;
  text-align: left;
}

.ReferralDialogItem {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  font-family: "Poppins", sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

@media (min-width: 350px) {
  .BottomNavigatorPreviewItemLabel {
    font-size: 14px;
    width: calc(100% - 70px);
  }

  .BottomNavigatorPickerButtonEnd {
    width: 35px;
  }
  .PrivacyConfigLabelTitle {
    font-size: 16px;
  }

  .PrivacyConfigLabelSub {
    font-size: 13px;
  }

  .SocialConfigButtonText {
    font-size: 12px;
  }
  .SocialConfigHelperText {
    font-size: 9px;
    height: 13px;
  }
  .UsernameTitle {
    font-size: 9px;
  }
  .PersonalSettingsTitle {
    font-size: 12px;
  }
    .ReferralsCountText {
      font-size: 11px;
    }
}

@media (min-width: 550px) {
  .PrivacyConfigLabelTitle {
    font-size: 17px;
  }

  .PrivacyConfigLabelSub {
    font-size: 14px;
  }

  .BottomNavigatorPreviewItemLabel {
    font-size: 15px;
    width: calc(100% - 80px);
  }

  .BottomNavigatorPickerButtonEnd {
    width: 40px;
  }

.SocialConfigButtonText {
  font-size: 13px;
}

.SocialConfigHelperText {
  font-size: 10px;
  height: 15px;
}

.UsernameTitle {
  font-size: 10px;
}

.PersonalSettingsTitle {
  font-size: 13px;
}

.ReferralsCountText {
  font-size: 13px;
}
  
}

@media (min-width: 700px) {
  .PrivacyConfigLabelTitle {
    font-size: 18px;
  }

  .PrivacyConfigLabelSub {
    font-size: 15px;
  }
  .BottomNavigatorPreviewItemLabel {
    font-size: 16px;
    width: calc(100% - 90px);
  }

  .BottomNavigatorPickerButtonEnd {
    width: 45px;
  }
  
    .SocialConfigHelperText {
      font-size: 11px;
      height: 17px;
    }
  
    .UsernameTitle {
      font-size: 11px;
    }
  
    .PersonalSettingsTitle {
      font-size: 14px;
    }
        .ReferralsCountText {
          font-size: 15px;
        }
}

.NewListingBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.NewListingBoxOuter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.NewListingBoxOuterText {
  font-size: 13px;
  font-weight: 600;
  margin: 0px;
  text-align: center;
  margin-left: 15px;
}

.NewListingBoxOuterTextNew {
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  text-align: center;
  margin-left: 15px;
}

.NewListingBoxOuterSub {
  font-size: 7px;
  font-weight: 400;
  margin: 0px;
  text-align: center;
  margin-left: 15px;
  margin-top: 5px;
}

.NewListingBoxOuterAlt {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.NewListingBoxOuterTextAlt {
  font-size: 15px;
  font-weight: 600;
  margin: 0px;
  text-align: left;
  width: 100%;
}

.NewListingBoxOuterSubAlt {
  font-size: 9px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
  margin-top: 2px;
  width: 100%;
}

.NewListingBoxInner {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.NewListingBoxSpan span {
  font-size: 25px;
}

.NewListingBoxSpanAlt span {
  font-size: 20px;
}

.preListProject {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 96%;
}

.preListProjectInner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 14px;
}

.preListProjectTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.preListProjectTopText {
  font-weight: 600;
  font-size: 10px;
  width: 48%;
  text-align: left;
  color: #39393c;
  margin: 0;
}

.preListProjectTopLast {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 48%;
}

.formOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.formDescription {
  font-weight: 400;
  font-size: 9px;
  width: 95%;
  text-align: left;
  color: #39393c;
  margin-left: 5px;
  min-height: 10px;
  margin-top: 2px;
}

.preListProjectPointsOuter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 4px;
  background-color: #ffffff;
}

.preListProjectPointsFirst {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  color: #39393c;
  background-color: #c4dbe2;
  border-radius: 3px 0px 0px 3px;
  padding: 0;
}

.preListProjectPointsText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 38%;
  color: #39393c;
  padding: 0;
}

.preListProjectPointsLast {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 37%;
  color: #39393c;
  padding: 0;
}

.preListProjectPointsNumber {
  font-weight: 500;
  font-size: 16px;
  width: 90%;
  text-align: center;
  color: #39393c;
}

.preListProjectPointsTitle {
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  text-align: center;
  color: #39393c;
}

.preListProjectPointsSub {
  font-weight: 500;
  font-size: 7px;
  width: calc(100% - 10px);
  text-align: left;
  color: #39393c;
  margin-left: 3px;
}

.preListProjectAcceptLast .formSub {
  width: 100%;
}

.preListProjectButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.NewListingGpt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -5px;
  margin-bottom: 10px;
}

.tagsOuter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.tagItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 20px;
}
.tagItemText {
  font-size: 10px;
  font-weight: 500;
  margin: 0;
  margin-right: 1px;
  padding: 0px;
}
.AutocompleteResultOuterNewListing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
}
.AutocompleteResultHeaderNewListing {
  font-size: 70%;
  font-weight: 500;
  color: rgba(57, 57, 60, 0.8);
  text-align: left;
  padding: 10px;
  margin: 0;
  background-color: #fdfcf8;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.AutocompleteResultTitleNewListing {
  font-size: 80%;
  font-weight: 500;
  color: #39393c;
  text-align: left;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-right: 16px;
}

@media (min-width: 350px) {
  .NewListingBoxOuterText {
    font-size: 15px;
  }
  .NewListingBoxOuterSub {
    font-size: 8px;
  }

  .NewListingBoxOuterTextAlt {
    font-size: 17px;
  }

  .NewListingBoxOuterSubAlt {
    font-size: 10px;
  }
  .NewListingBoxSpan span {
    font-size: 28px;
  }
  .preListProjectTopText {
    font-size: 11.5px;
  }
  .formDescription {
    font-size: 10px;
    min-height: 10px;
    margin-top: 2px;
  }
  .preListProjectPointsNumber {
    font-size: 18px;
  }

  .preListProjectPointsTitle {
    font-size: 18px;
  }

  .preListProjectPointsSub {
    font-size: 8px;
  }
}

@media (min-width: 450px) {
  .preListProjectTopText {
    font-size: 14px;
  }

  .formDescription {
    font-size: 11px;
  }

  .preListProjectPointsNumber {
    font-size: 19px;
  }

  .preListProjectPointsTitle {
    font-size: 19px;
  }

  .preListProjectPointsSub {
    font-size: 10px;
  }
}

@media (min-width: 550px) {
  .NewListingBoxOuterText {
    font-size: 19px;
  }
  .NewListingBoxOuterSub {
    font-size: 10px;
  }
  .NewListingBoxOuterTextAlt {
    font-size: 21px;
  }

  .NewListingBoxOuterSubAlt {
    font-size: 12px;
  }
  .NewListingBoxSpan span {
    font-size: 32px;
  }
  .preListProjectTopText {
    font-size: 16px;
  }

  .formDescription {
    font-size: 12px;
  }

  .preListProjectPointsNumber {
    font-size: 22px;
  }

  .preListProjectPointsTitle {
    font-size: 22px;
  }

  .preListProjectPointsSub {
    font-size: 12px;
  }
}

@media (min-width: 701px) {
  .NewListingBoxOuterText {
    font-size: 22px;
  }
  .NewListingBoxOuterSub {
    font-size: 11px;
  }
  .NewListingBoxOuterTextAlt {
    font-size: 24px;
  }

  .NewListingBoxOuterSubAlt {
    font-size: 13px;
  }
  .NewListingBoxSpan span {
    font-size: 35px;
  }
}

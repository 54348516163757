.AdminListItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 200px;
  border-radius: 20px;
}

.AdminListItemTitleOuter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  border-radius: 18px 18px 0px 0px;
  padding-bottom: 10px;
  padding-top: 14px;
}

.AdminListItemTitle {
  font-size: 16px;
  margin: 0;
  margin-left: 10px;
  flex: 1;
  font-weight: 600;
  text-align: left;
  padding: 0;
}

.AdminListItemSub {
  font-size: 14px;
  margin: 0;
  margin-right: 10px;
  font-weight: 500;
  text-align: right;
  padding: 0;
  font-style: italic;
}

.AdminListItemInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: calc(100% - 20px);
  padding-top: 10px;
  margin-bottom: 16px;
  overflow: hidden;
}

.AdminListItemInfoText {
  font-size: 14px;
  margin: 0;
  margin-right: 5px;
  min-width: 48%;
  text-align: left;
  margin-bottom: 5px;
}

.AdminUserPreRegItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  min-height: 80px;
  border-radius: 5px;
  margin-top: 10px;
}

.AdminUserPreRegItemInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  margin-top: 5px;
  margin-bottom: 3px;
}
.AdminUserPreRegItemButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 50px;
}

.AdminUserPreRegItemText {
  font-size: 14px;
  text-align: left;
  margin: 10px;
  width: 100%;
  text-overflow: ellipsis;
}

.AdminNewsletterItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 46px;
  border-radius: 5px;
  margin-top: 10px;
}

.AdminNewsLinkItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 14px;
  padding-bottom: 6px;
  margin-bottom: 0;
  padding-top: 0;
}

.AdminUserListItemSubOuter 
{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: hidden;
}

.AdminUserListItemSub {
  font-size: 11px;
  margin: 0;
  margin-right: 10px;
  font-weight: 400;
  text-align: left;
  padding: 0;
  margin-top: 10px;
  text-overflow: ellipsis;
  word-break: break-all;
}

.AdminUserListItemActive {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
}

@media (min-width: 600px) {
  .AdminUserPreRegItem {
    min-height: 120px;
  }
}

@media (min-width: 1000px) {
}

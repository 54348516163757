.newsListingsOuter {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
    flex: 1;
    min-height: 150px;
    text-align: left;
}

.loadingDialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #39393c;
    flex: 1;
    width: 100%;
    min-height: 150px;
}

.newsListingsText {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: #999999;
}

.outsideKeyListing {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rss3Listing {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    border-radius: 10px;
    padding-top: 20px;
    position: relative;
    max-height: 300px;
    transition: all 0.5s ease-in-out;
}

.rssListingBottomGradient {
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 31px;
    left: 0;
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.7));
}

.rssListingFooter {
    width: 94%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-radius: 0px 0px 10px 10px;
}

.rssListingFooterInner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 8px;
    text-decoration: none;
}

.rssListingFooterText {
    font-size: 9px;
    font-weight: 500;
}

.rss3ListingHeader {
    width: 92%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.rss3ListingHeaderInner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 8px;
    overflow: hidden;
}

.rss3ListingTitle {
    text-align: left;
    font-size: 10px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    width: 96%;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.rss3ListingSubtitle {
    text-align: left;
    font-size: 8px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    width: 96%;
    margin-top: 2px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.rss3ListingText {
    text-align: left;
    font-size: 9px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    width: 92%;
    margin-top: 8px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    flex: 1;
    white-space: pre-line;
    overflow: hidden;
    padding-bottom: 6px;
}

.markdownOuter {
    padding: 0;
    margin: 0;
    width: 92%;
    margin-top: 8px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    flex: 1;
    white-space: pre-line;
    overflow: hidden;
    padding-bottom: 6px;
    font-size: 9px;
    text-align: left;
}

.markdownOuter p {
    font-size: 9px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: left;
}

.markdownOuter figcaption {
    font-size: 9px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: left;
}

.markdownOuter h1 {
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: left;
}

.markdownOuter h2 {
    font-size: 9px;
    font-family: "Poppins", sans-serif;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: left;
}

.markdownOuter h3 {
    font-size: 9px;
    font-family: "Poppins", sans-serif;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: left;
}

.markdownOuter h4 {
    font-size: 9px;
    font-family: "Poppins", sans-serif;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: left;
}

.markdownOuter h5 {
    font-size: 9px;
    font-family: "Poppins", sans-serif;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: left;
}

.markdownOuter h6 {
    font-size: 9px;
    font-family: "Poppins", sans-serif;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: left;
}

.markdownOuter img {
    width: 80%;
    height: auto;
    margin-left: 10%;
    margin-top: 4px;
    margin-bottom: 4px;
}

.markdownOuter blockquote {
    font-size: 8px;
    margin: 20px;
}

.markdownOuter ul {
    padding-inline-start: 10px;
}

@media (min-width: 350px) {
    .newsListingsText {
        font-size: 13px;
    }

    .rss3ListingTitle {
        font-size: 12px;
    }

    .rss3ListingSubtitle {
        font-size: 10px;
    }

    .rss3ListingText {
        font-size: 10px;
        margin-top: 12px;
    }

    .markdownOuter {
        margin-top: 10px;
    }

    .markdownOuter {
        font-size: 10px;
    }

    .markdownOuter p {
        font-size: 10px;
    }

    .markdownOuter figcaption {
        font-size: 10px;
    }

    .markdownOuter h1 {
        font-size: 11px
    }

    .markdownOuter h2 {
        font-size: 10px;
    }

    .markdownOuter h3 {
        font-size: 10px;
    }

    .markdownOuter h4 {
        font-size: 10px;
    }

    .markdownOuter h5 {
        font-size: 10px;
    }

    .markdownOuter h6 {
        font-size: 10px;
    }

    .rssListingFooterText {
        font-size: 10px;
    }

    .markdownOuter blockquote {
        font-size: 9px;
    }
}

@media (min-width: 550px) {
    .newsListingsText {
        font-size: 14px;
    }

    .rss3ListingTitle {
        font-size: 13px;
    }

    .rss3ListingSubtitle {
        font-size: 11px;
    }

    .rss3ListingText {
        font-size: 11px;
        margin-top: 12px;
    }

    .markdownOuter {
        margin-top: 11px;
    }

    .markdownOuter {
        font-size: 11px;
    }

    .markdownOuter p {
        font-size: 11px;
    }

    .markdownOuter figcaption {
        font-size: 11px;
    }

    .markdownOuter h1 {
        font-size: 12px
    }

    .markdownOuter h2 {
        font-size: 11px;
    }

    .markdownOuter h3 {
        font-size: 11px;
    }

    .markdownOuter h4 {
        font-size: 11px;
    }

    .markdownOuter h5 {
        font-size: 11px;
    }

    .markdownOuter h6 {
        font-size: 11px;
    }

    .rssListingFooterText {
        font-size: 11px;
    }

    .markdownOuter blockquote {
        font-size: 10px;
    }
}

@media (min-width: 701px) {
    .newsListingsText {
        font-size: 15px;
    }

    .rss3ListingTitle {
        font-size: 14px;
    }

    .rss3ListingSubtitle {
        font-size: 12px;
    }

    .rss3ListingText {
        font-size: 12px;
        margin-top: 12px;
    }

    .markdownOuter {
        margin-top: 12px;
    }

    .markdownOuter {
        font-size: 12px;
    }

    .markdownOuter p {
        font-size: 12px;
    }

    .markdownOuter figcaption {
        font-size: 12px;
    }

    .markdownOuter h1 {
        font-size: 13px
    }

    .markdownOuter h2 {
        font-size: 12px;
    }

    .markdownOuter h3 {
        font-size: 12px;
    }

    .markdownOuter h4 {
        font-size: 12px;
    }

    .markdownOuter h5 {
        font-size: 12px;
    }

    .markdownOuter h6 {
        font-size: 12px;
    }

    .rssListingFooterText {
        font-size: 12px;
    }

    .markdownOuter blockquote {
        font-size: 11px;
    }
}
.AutocompleteResultHeader {
    font-size: 90%;
    font-weight: 700;
    color: rgba(57, 57, 60, 0.8);
    text-align: left;
    padding: 10px;
    margin: 0;
    background-color: #fdfcf8;
    width: 100%;
    margin-top: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.AutocompleteResultTitle {
    font-size: 105%;
    font-weight: 500;
    color: #39393c;
    text-align: left;
    flex: 1;
    padding: 0;
    margin: 0;
    margin-right: 16px;
}

.AutocompleteResultText {
    font-size: 95%;
    font-weight: 400;
    color: rgba(57, 57, 60, 0.8);
    text-align: right;
    padding: 0;
    margin: 0;
    font-style: italic;
}
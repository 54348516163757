.Dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 100px;
  padding-top: 0px;
  margin-top: -10px;
  font-family: "Poppins", sans-serif;
}
.DashboardNewListingBox {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}
.DashboardWidgetsOuter {
  width: 90%;
  max-width: 800px;
  column-fill: balance;
  columns: 1;
}
.DashboardRowOuter {
  width: 100%;
  display: inline-block;
  margin-top: 15px;
}

.DashboardRowDrag {
  width: calc(100% - 10px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: -1px;
  border-radius: 0px;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: -1px;
}
.DashboardRowDragText {
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
  flex: 1;
}

.DashboardRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.DashboardRowFull {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.DashboardRowHalf {
  width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.DashboardRowQuarter {
  width: calc(25% - 12px) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.DashboardOpenEdit {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.DashboardOpenEditTitle {
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
  margin-right: 5px;
  text-align: center;
}

.DashboardAddWidgetTextSmall {
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
  text-align: center;
  margin-right: 0px;
}

.DashboardAddWidgetText {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  text-align: center;
  margin-right: 5px;
}

.DashboardOpenEdit span {
  font-size: 15px;
}

.AddWidgetDialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 30px;
  margin-top: -20px;
}

.AddWidgetHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
  margin-top: 40px;
}

.AddWidgetHeaderTitle {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  text-align: left;
  padding: 0px;
  margin-bottom: 2px;
}

.AddWidgetHeaderSub {
  font-size: 8px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
  line-height: 90%;
  flex: 1;
  padding: 0px;
  margin-bottom: 5px;
}

.DialogCloseBottom {
    width: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
    padding-top: 4px;
    margin-left: 4%;
    position: absolute;
    bottom: 0px;
}

@media (min-width: 350px) {
  .AddWidgetHeaderTitle {
    font-size: 15px;
  }

  .AddWidgetHeaderSub {
    font-size: 10px;
  }
}

@media (min-width: 450px) {
  .AddWidgetHeaderTitle {
    font-size: 16px;
  }

  .AddWidgetHeaderSub {
    font-size: 11px;
  }
}

@media (min-width: 550px) {
  .AddWidgetHeaderTitle {
    font-size: 18px;
  }

  .AddWidgetHeaderSub {
    font-size: 13px;
  }
}

@media (min-width: 600px) {
  .DashboardRowOuter {
    width: 100%;
  }
  .DashboardWidgetsOuter {
    columns: 2;
  }
}

@media (min-width: 700px) {
  .AddWidgetHeaderTitle {
    font-size: 19px;
  }

  .AddWidgetHeaderSub {
    font-size: 14px;
  }
}

.SearchAndExplore {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0px;
  margin-bottom: 20px;
  width: calc(90% - 30px);
  padding: 15px;
  max-width: 1170px;
  border-radius: 8px;
  padding-bottom: 10px;
  position: relative;
}

.SearchAndExploreHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 15px;
  width: 100%;
}

.SearchAndExploreHeaderTitle {
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 2px;
  text-align: left;
}

.SearchAndExploreHeaderSub {
  font-size: 8px;
  font-weight: 300;
  margin: 0px;
  text-align: left;
  margin-left: 2px;
}

.SearchAndExploreHeaderSubLarge {
  font-size: 12px;
  font-weight: 300;
  margin: 0px;
  text-align: left;
  margin-left: 2px;
}

.ExploreInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - 18px);
  margin-top: 10px;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.5s ease-in-out;
}

.ExploreInfoText {
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
  text-align: left;
  margin-right: 15px;
  flex: 1;
}

.ExploreSearchSuggestionsOuter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: calc(100% - 10px);
  margin-top: 10px;
  padding: 5px;
}

.ExploreSearchSuggestionsText {
  font-size: 9px;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  transition: all 0.5s ease-in-out;
}

.DashboardInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.DashboardInfoInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  margin: 2px;
}

.DashboardInfoNew {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 30px;
}

.DashboardInfoInnerNew {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 98%;
  margin-bottom: 40px;
}

.DashboardInfoInnerLogo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 70%;
  margin-right: 4%;
}

.DashboardInfoTitle {
  margin-top: 10px;
  font-size: 15px;
  margin-bottom: 0px;
  font-weight: 600;
  text-align: left;
}

.DashboardInfoSub {
  margin-top: 10px;
  font-size: 11px;
  font-weight: 700;
  text-align: left;
}

.DashboardInfoText {
  margin-top: 10px;
  font-size: 9px;
  font-weight: 500;
  text-align: left;
}

.DashboardInfoUL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  margin-top: 0px;
  padding: 0px;
  padding-left: 5%;
}

.DashboardInfoLI {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.DashboardInfoLogo {
  width: 90%;
  margin: 0px;
  padding: 0px;
}

.IssueReportInfoInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  margin-top: 0px;
}

@media (min-width: 350px) {
  .SearchAndExploreHeaderTitle {
    font-size: 28px;
  }

  .SearchAndExploreHeaderSub {
    font-size: 9px;
  }
  .SearchAndExploreHeaderSubLarge {
    font-size: 15px;
  }
  .ExploreInfoText {
    font-size: 11.5px;
  }
  .ExploreSearchSuggestionsText {
    font-size: 10px;
  }
  .DashboardInfoTitle {
    font-size: 18px;
  }
  .DashboardInfoSub {
    font-size: 13px;
  }
  .DashboardInfoText {
    font-size: 11px;
  }
}

@media (min-width: 450px) {

  .DashboardInfoTitle {
    font-size: 24px;
  }

  .DashboardInfoSub {
    font-size: 13px;
  }

  .DashboardInfoText {
    font-size: 11px;
  }
}

@media (min-width: 550px) {
  .SearchAndExploreHeaderTitle {
    font-size: 33px;
  }

  .SearchAndExploreHeaderSub {
    font-size: 11px;
  }
  .SearchAndExploreHeaderSubLarge {
    font-size: 18px;
  }

  .ExploreInfoText {
    font-size: 13px;
  }
  .ExploreSearchSuggestionsText {
    font-size: 11px;
  }

  .DashboardInfoTitle {
    font-size: 28px;
  }
  .DashboardInfoSub {
    font-size: 15px;
  }
  .DashboardInfoText {
    font-size: 13px;
  }
}

@media (min-width: 701px) {
  .SearchAndExploreHeaderTitle {
    font-size: 38px;
  }

  .SearchAndExploreHeaderSub {
    font-size: 13px;
  }
  .SearchAndExploreHeaderSubLarge {
    font-size: 21px;
  }

  .ExploreInfoText {
    font-size: 15px;
  }
  .ExploreSearchSuggestionsText {
    font-size: 12px;
  }
    .DashboardInfoNew {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  .DashboardInfoInnerNew {
    width: 56%;
    margin-bottom: 0px;
  }

  .DashboardInfoInnerLogo {
    width: 42%;
    margin-right: -2%;
  }
  .DashboardInfoTitle {
    font-size: 23px;
  }
  .DashboardInfoSub {
    font-size: 13px;
  }
  .DashboardInfoText {
    font-size: 11px;
  }
}

@media (min-width: 900px) {
  .DashboardInfoTitle {
    font-size: 26px;
  }
  .DashboardInfoSub {
    font-size: 15px;
  }
  .DashboardInfoText {
    font-size: 13px;
  }

    .DashboardInfoInnerNew {
      width: 59%;
    }
  
    .DashboardInfoInnerLogo {
      width: 39%;
    }
}

.TokensPage {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 120px;
}

.TokensPagePrices {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100% - 2px);
    margin-top: 0px;
    margin-bottom: 5px;
    transition: all 0.5s ease-in-out;
}

.TokensPagePricesItem {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    transition: all 0.5s ease-in-out;
    margin-bottom: 10px;
    margin-top: 10px;
}

.TokensPagePricesItemInnerFull {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px;
}
.TokensPagePricesItemInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(33.333% - 20px);
    margin: 10px;
}

.TokensPagePricesItemTile {
    font-size: 8px;
    font-weight: 700;
    margin: 0px;
    margin-bottom: 2px;
    text-align: center;
}

.TokensPagePricesItemSub {
    font-size: 10px;
    font-weight: 500;
    margin: 0px;
    text-align: center;
}

.TokensPagePricesChart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    margin-left: 1%;
    margin-right: 1%;
    aspect-ratio: 2;
    border-radius: 8px;
    margin-bottom: 5px;
}

@media (min-width: 400px) {
    .TokensPagePricesItemTile {
        font-size: 10px;
    }

    .TokensPagePricesItemSub {
        font-size: 12px;
    }
}

@media (min-width: 500px) {
    .TokensPagePricesItemTile {
        font-size: 9px;
    }

    .TokensPagePricesItemSub {
        font-size: 11px;
    }

    .TokensPagePricesItemInner {
        width: calc(20% - 20px);
    }
        .TokensPagePricesChart {
            width: 30%;
            aspect-ratio: 2.2;
            margin-bottom: 10px;
        }
}

@media (min-width: 600px) {
    .TokensPagePricesItemTile {
        font-size: 10px;
    }

    .TokensPagePricesItemSub {
        font-size: 12px;
    }
}

@media (min-width: 700px) {
    .TokensPagePricesItemTile {
        font-size: 11px;
    }

    .TokensPagePricesItemSub {
        font-size: 13px;
    }
        .TokensPagePricesChart {
            margin-bottom: 15px;
        }
}
.WalletOuter {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 120px;
}

.WalletHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  max-width: 800px;
}

.WalletPageHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: calc(90% - 30px);
  max-width: 800px;
  padding: 15px;
  border-radius: 8px;
  margin-top: 80px;
}
.WalletPageHeaderTitle {
  font-weight: 600;
  font-size: 14px;
  width: 80%;
  text-align: left;
  text-decoration: none;

  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  margin-bottom: 3px;
}

.WalletPageHeaderSub {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  margin-top: 0px;
  width: 70%;
}

.WalletHeaderLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: calc(90% + 2px);
  max-width: 802px;
  text-decoration: none;
  margin-top: 20px;
}

.WalletHeaderLinksInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.WalletHeaderLinksText {
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
}

.WalletBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 92%;
  max-width: 800px;
}
.WalletBodyEditHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
}
.WalletBodyLoading {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.WalletListItemLinkOuter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  text-decoration: none;
  margin: 0px;
}
.WalletListItem {
  width: calc(100% - 4px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.WalletListItemDeleteOuter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  transition: all 0.2s ease;
}

.WalletListItemInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0px;
  padding: 0px;
  text-decoration: none;
  width: 80%;
}

.WalletListItemTitle {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 8px);
  padding-left: 8px;
}

.WalletListItemSub {
  font-size: 10px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 8px);
  padding-left: 8px;
}

.WalletItemOuter {
  width: 100%;
}

.NewWalletDialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

@media (min-width: 350px) {
  .WalletListItemTitle {
    font-size: 15px;
  }

  .WalletListItemSub {
    font-size: 11px;
  }
  .WalletHeaderLinksText {
    font-size: 13px;
  }

  .WalletPageHeaderSub {
    font-size: 12px;
  }
}

@media (min-width: 550px) {
  .WalletListItemTitle {
    font-size: 16px;
  }

  .WalletListItemSub {
    font-size: 12px;
  }
  .WalletHeaderLinksText {
    font-size: 14px;
  }
  .WalletPageHeaderSub {
    font-size: 13px;
  }
}

@media (min-width: 701px) {
  .WalletListItemTitle {
    font-size: 17px;
  }

  .WalletListItemSub {
    font-size: 13px;
  }
  .WalletHeaderLinksText {
    font-size: 15px;
  }
  .WalletPageHeaderSub {
    font-size: 14px;
  }
}

.ListingsPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 90px;
}

.ListingsPageTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(90% - 30px);
  padding: 15px;
  max-width: 770px;
  border-radius: 8px;
  margin-top: -10px;
  transition: all 1s ease-in-out;
  white-space: pre-wrap;
}

.ListingsPageTopInner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.ListingsPageTopInnerAvatar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ListingsPageTopInnerText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
}

.ListingsPageTopInnerIcons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.ListingsPageHeaderTitle {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-left: 15px;
}

.ListingsPageHeaderSub {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-left: 16px;
  margin-top: 2px;
}

.ListingsPageHeaderDesc {
  text-align: left;
  font-size: 10px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  margin-top: 5px;
}

.ListingsPageHeaderRating {
  width: 100%;
  margin-top: 10px;
}

.ListingsPageDescSocial {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ListingsPageHeaderSocialLink {
  width: 20px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.ListingsPageTopInnerDivider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 1.5px;
  margin-bottom: 10px;
}

.ListingsPageTopFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.ListingsPageTopFooterButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.ListingsPageTopTagsOuter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: -5px;
  margin-bottom: 10px;
  min-height: 20px;
}

.ListingsPageTopTags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}

.ListingsPageTag {
  text-align: center;
  font-size: 9px;
  font-weight: 400;
  padding: 0px 5px 0px 5px;
  border-radius: 60px;
  margin-bottom: 0px;
  margin-right: 10px;
}

.ListingsPageTopFooterInner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
}

.ListingsPageTopFooterTextItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 23%;
  margin-right: 2%;
  margin-bottom: 10px;
  overflow: hidden;
}
.ListingsPageTopFooterTextItemBlur {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
    min-width: 23%;
    margin-right: 2%;
  margin-bottom: 10px;
  filter: blur(1.5px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.ListingsPageTopFooterTitle {
  text-align: left;
  font-size: 8px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  margin-bottom: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ListingsPageTopFooterSub {
  text-align: left;
  font-size: 10px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ListingsPageWebsiteLink {
  text-align: left;
  font-size: 10px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-bottom: -3px;
  margin-top: 10px;
  width: 90%;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ListingsPageTopBottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 10px;

}

.ListingsPageTopAuthor {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 20px;

}

.ListingsPageTrending {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #393c3a;
  flex: 1;
  padding: 0;
  margin: 0;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ListingsPageTabsOuter {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  max-width: 800px;
}

.ListingsPageTabs {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 0px;
}

.ListingsPageTabsInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.ListingsPageTabsText {
  font-weight: 500;
  font-size: 11px;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

.radioButtons {
  width: 87%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding-top: 0px;
  padding-bottom: 25px;
  margin-left: 4%;
  margin-right: 5%;
}

.NewsOuter {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 0px;
}

@media (min-width: 350px) {
  .ListingsPageHeaderTitle {
    font-size: 24px;
  }
  .ListingsPageHeaderSub {
    font-size: 14px;
  }
  .ListingsPageHeaderDesc {
    font-size: 11px;
  }

  .ListingsPageHeaderSocialLink {
    width: 23px;
  }

  .ListingsPageWebsiteLink {
    font-size: 11px;
  }

  .ListingsPageTopFooterTitle {
    font-size: 9px;
  }

  .ListingsPageTopFooterSub {
    font-size: 11px;
  }

  .ListingsPageTag {
    font-size: 10px;
  }

  .ListingsPageTabsText {
    font-size: 12px;
  }

  .radioButtons {
    padding-top: 0px;
    padding-bottom: 30px;
  }
}

@media (min-width: 550px) {
  .ListingsPageHeaderTitle {
    font-size: 28px;
  }
  .ListingsPageHeaderSub {
    font-size: 16px;
  }

  .ListingsPageHeaderDesc {
    font-size: 12px;
  }

  .ListingsPageHeaderSocialLink {
    width: 28px;
  }

  .ListingsPageWebsiteLink {
    font-size: 12px;
  }

  .ListingsPageTopFooterTitle {
    font-size: 10px;
  }

  .ListingsPageTopFooterSub {
    font-size: 12px;
  }

  .ListingsPageTag {
    font-size: 12px;
  }
  .ListingsPageTabsText {
    font-size: 14px;
  }

  .radioButtons {
    padding-top: 0px;
    padding-bottom: 36px;
  }
}

@media (min-width: 701px) {
  .ListingsPageHeaderTitle {
    font-size: 32px;
  }

  .ListingsPageHeaderSub {
    font-size: 18px;
  }

  .ListingsPageHeaderDesc {
    font-size: 13px;
  }

  .ListingsPageHeaderSocialLink {
    width: 31px;
  }

  .ListingsPageWebsiteLink {
    font-size: 14px;
  }

  .ListingsPageDesc {
    font-size: 15px;
  }

  .ListingsPageTag {
    font-size: 13px;
  }

  .ListingsPageTabsText {
    font-size: 15px;
  }

  .radioButtons {
    padding-top: 0px;
    padding-bottom: 40px;
  }
}

.standardSvg {
  width: 100%;
  height: auto;
  overflow: visible;
}

.registerSvg {
  width: 31%;
  height: auto;
  overflow: visible;
}

.googleSvg {
  width: 96%;
  height: auto;
  overflow: visible;
}

.socialsLogoSvg {
  width: 90%;
  aspect-ratio: 1;
  overflow: visible;
}

.LogoMask {
  width: 100%;
  height: auto;
  overflow: visible;
}

.PfpViewer {
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PfpViewerImg {
  width: 100%;
  border-radius: 8px;
  height: 100%;
  position: absolute;
}
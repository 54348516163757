.ErrorUrl {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ErrorUrlHeader {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    width: 80%;
    max-width: 1000px;
    min-width: none;
}
.ErrorUrlLogo {
    width: 37%;
    margin-right: 3%;
    opacity: 0.98;
}
.ErrorUrlMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 58%;
    margin-left: 2%;
}
.ErrorUrlTitle {
    font-size: 25vmin;
    color: #1a1a1a;
    width: 100%;
    text-align: center;
    margin: 0;
    margin-top: -20px;
}
.ErrorUrlText {
    font-size: 1.9vmin;
    color: #1a1a1a;
    width: 100%;
    text-align: center;
    margin: 0;
    margin-top: -10px;
}
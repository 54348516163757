.ProfileCount {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 10px;
}

.ProfileCountItem {
  width: calc(17% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 10px;
  padding: 10px;
}

.ProfileCountItemBlur {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(1.5px);
  margin-top: 2px;
  text-decoration: none;
}

.ProfileCountTitle {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  margin-bottom: 2px;
  text-decoration: none;
}

.ProfileCountSub {
  font-weight: 400;
  font-size: 9px;
  margin: 0;
  text-decoration: none;
}

.ProfileAchivements {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
}

.ProfilePointsLink {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  margin-top: 0px;
  text-decoration: none;
}

.UserPageCountV2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 10px;
}

.UserPageCountV2Item {
  width: calc(17% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 6px;
  padding: 10px;
}

.UserPageCountV2Title {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  margin-bottom: 2px;
  text-decoration: none;
}

.UserPageCountV2Sub {
  font-weight: 400;
  font-size: 10px;
  margin: 0;
  text-decoration: none;
}

.UserPageV2Achievements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-top: 0px;
}

.UserPageV2AchievementsLoading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}

.UserPageV2AchievementsInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.UserPageV2AchievementsSlider {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.UserPageV2AchievementsItem {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    z-index: 100;
}

.UserPageV2AchievementsItem span {
    font-size: 30px;
    margin-bottom: 0px;
    width: 100%;
}

.UserPageV2AchievementsListItemImg {
    width: 100%;
    aspect-ratio: 1;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
}
.UserPageV2ReviewsPreviewOuter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0px;
}

.UserPageV2ReviewsPreviewTitle {
    font-family: "Kensington", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
}

.UserPageV2ReviewsPreviewInner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: -10px;
}

.UserPageV2ReviewsPreviewInnerLink {
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    width: calc(100% - 22px);
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
}

@media (min-width: 350px) {
  .ProfileCountTitle {
    font-size: 19px;
  }

  .ProfileCountSub {
    font-size: 10px;
  }
}

@media (min-width: 550px) {
  .ProfileCountTitle {
    font-size: 21px;
  }

  .ProfileCountSub {
    font-size: 11px;
  }
}

@media (min-width: 700px) {
  .ProfileCountTitle {
    font-size: 23px;
  }

  .ProfileCountSub {
    font-size: 11px;
  }
}

@media (max-width: 1200px) {
  .UserPageCountV2Title {
    font-size: 18px;
  }

  .UserPageCountV2Sub {
    font-size: 9px;
  }
}
@media (max-width: 1000px) {
    .UserPageV2ReviewsPreviewInnerLink {
            font-size: 13px;
        }
}
@media (max-width: 800px) {
  .UserPageCountV2Title {
    font-size: 16px;
  }

  .UserPageCountV2Sub {
    font-size: 8px;
  }
}
@media (max-width: 600px) {
  .UserPageCountV2Title {
    font-size: 14px;
  }

  .UserPageCountV2Sub {
    font-size: 7px;
  }

  .UserPageV2ReviewsPreviewTitle {
    text-align: center;
        font-size: 26px;
  }

  .UserPageV2ReviewsPreviewInner {
    width: calc(100%);
        margin-left: 0px;
  }

  .UserPageV2ReviewsPreviewInnerLink {
    text-align: center;
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .UserPageCountV2Title {
    font-size: 12px;
  }

  .UserPageCountV2Sub {
    font-size: 6px;
  }
  .UserPageV2ReviewsPreviewTitle {
      font-size: 22px;
  }
  .UserPageV2ReviewsPreviewInnerLink {
      font-size: 10px;
  }
}

.Reviews {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 100px;
}

.UserReviewsItemOuter {
  width: calc(90% + 2px);
    max-width: 802px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0px;
}

.UserReviewsLinkBack {
  font-size: 13px;
  font-weight: 500;
  margin-top: 30px;
}

.ReviewsListTabs {
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 0px;
}

.ReviewsOuter {
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

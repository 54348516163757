.AddWidget {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-top: 0px;
}

.AddWidgetSettingsOuter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.AddWidgetSettings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.AddWidgetSettingsInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 96%;
  padding: 0px;
  margin: 0px;
  margin-left: 2%;
  margin-right: 2%;
  overflow: hidden;
  transition: all 0.3s ease;
}

.WidgetColorPicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.WidgetColorPickerTitle {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin: 0px;
  padding: 0px;
  margin-bottom: 2px;
  margin-top: 15px;
  margin-left: 5px;
  width: 100%;
}
.WidgetColorPickerInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12.5%;
    aspect-ratio: 1/1;
    padding: 0px;
    margin: 0px;
    position: relative;
}
.WidgetColorPickerInnerOverlay {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    opacity: 0.3;
}
